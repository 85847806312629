/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./property-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/leaflet-map/leaflet-map.component.ngfactory";
import * as i3 from "../../components/leaflet-map/leaflet-map.component";
import * as i4 from "@angular/router";
import * as i5 from "./property-map.component";
import * as i6 from "../../core/http/api.service";
var styles_PropertyMapComponent = [i0.styles];
var RenderType_PropertyMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropertyMapComponent, data: {} });
export { RenderType_PropertyMapComponent as RenderType_PropertyMapComponent };
export function View_PropertyMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "width: 100vw; height: calc(100vh - 60px)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-leaflet-map", [], null, null, null, i2.View_LeafletMapComponent_0, i2.RenderType_LeafletMapComponent)), i1.ɵdid(2, 4833280, null, 0, i3.LeafletMapComponent, [i4.Router], { centerOfTheMap: [0, "centerOfTheMap"], mapId: [1, "mapId"], properties: [2, "properties"], showAllProperties: [3, "showAllProperties"], showZoomControl: [4, "showZoomControl"], defaultZoom: [5, "defaultZoom"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.centerOfTheMap; var currVal_1 = "mapOfProperties"; var currVal_2 = _co.propertyPois; var currVal_3 = true; var currVal_4 = true; var currVal_5 = 15; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_PropertyMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-property-map", [], null, null, null, View_PropertyMapComponent_0, RenderType_PropertyMapComponent)), i1.ɵdid(1, 114688, null, 0, i5.PropertyMapComponent, [i6.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropertyMapComponentNgFactory = i1.ɵccf("app-property-map", i5.PropertyMapComponent, View_PropertyMapComponent_Host_0, {}, {}, []);
export { PropertyMapComponentNgFactory as PropertyMapComponentNgFactory };
