import * as i0 from "@angular/core";
import * as i1 from "../http/api.service";
export class GuideService {
    constructor(api) {
        this.api = api;
        this.currentGuideId = '';
    }
    // lang: string
    getGuRules() {
        return this.api.getAllGurules();
    }
    addGurule(gurule) {
        return this.api.addGurule(gurule);
    }
    updateGurule(guruleId) {
        return this.api.updateGurule(guruleId);
    }
    deleteGurule(guruleId) {
        return this.api.deleteGurule(guruleId);
    }
}
GuideService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuideService_Factory() { return new GuideService(i0.ɵɵinject(i1.ApiService)); }, token: GuideService, providedIn: "root" });
