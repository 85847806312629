export class FunFactResults {
  nbRecommendations: number;
  nbYaagoRecommendation: number;
  nbDataGouvRecommendation: number;
  nbRecommendationsLiked: number;

  nbBookletOpenned: number;

  nbSmsMsgIn: number;
  nbWhatsAppMsgIn: number;
  nbDirectMsgIn: number;

  nbSmsMsgOut: number;
  nbWhatsAppMsgOut: number;
  nbDirectMsgOut: number;

  nbOnlineCheck: number;
  nbOnlinePreCheck: number;
  nbBookletReviews: number;

  guestCountries: number;

  totalProperties: number;
  totalPausedProperties: number;
  totalUnPausedProperties: number;
  totalUsers: number;
  totalTrailPeriodUser: number;
  totalPaidUsers: number;
  totalPropertyBookings: number;


  totalGuides: number;

  totalPropertiesWithUpsells: number;
  totalUpsellsProducts: number;
  totalAmountDue: number;
  numberOfMobileUsers: number;

  numberOfActiveUsers: number;
  numberOfActiveMobileUsers: number;
}
