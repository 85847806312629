import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../core/services/admin.service";
import {YaagoAmbassador} from "../../model/YaagoAmbassador";
import {UtilService} from "../../core/services/UtilService";

@Component({
  selector: 'app-m7-ambassador',
  templateUrl: './m7-ambassador.component.html',
  styleUrls: ['./m7-ambassador.component.scss']
})
export class M7AmbassadorComponent implements OnInit {

  yaagoAmbassadors: YaagoAmbassador[] = [];
  constructor(private adminService: AdminService, public utilService: UtilService) { }

  ngOnInit() {
    this.adminService.getAmbassadors().subscribe( res => {
      this.yaagoAmbassadors = res.sort((a, b) => a.expirationDate > b.expirationDate ? -1 : 1);
    }, err => {

    });
  }

}
