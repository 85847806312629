
<div class="y3-padding-8">

  <div class="y3-margin-top-16 y3-Medium y3-bold txt-color-grvogue">{{airbnbAccount.length}} Airbnb OtaAccounts </div>

  <table  style="margin-top: 8px;">
    <tr>
      <th style="text-align: left">Corporate Owner</th>
      <th style="text-align: left">Airbnb Email</th>
      <th>Properties</th>
      <th>LastSynch Date</th>
      <th>Total Elapse Time</th>
      <th>Elapse For Properties</th>
      <th>Elapse For Booking</th>
    </tr>

    <tr *ngFor="let otaAccount of airbnbAccount; let i = index" >
      <td style="text-align: left">{{otaAccount.propertyOwnerPersonEmail}}</td>
      <td style="text-align: left">{{otaAccount.airbnbEmail}}</td>
      <td>{{otaAccount.numberOfProperties}}</td>
      <td>{{utilService.formatDateTime(otaAccount.lastSyncDatetime)}}</td>
      <td>{{otaAccount.lastSyncElapseTime}}</td>
      <td>{{otaAccount.lastSyncElapseTimeForProperty}}</td>
      <td>{{otaAccount.lastSyncElapseTimeForBooking}}</td>
    </tr>

  </table>

  <div class="y3-margin-top-16 y3-Medium y3-bold txt-color-grvogue">{{bookingDotComAccount.length}} Booking Dot Com OtaAccounts</div>

  <table  style="margin-top: 8px;">
    <tr>
      <th style="text-align: left">Corporate Owner</th>
      <th style="text-align: left">Pulse Id</th>
      <th>Properties</th>
      <th>LastSynch Date</th>
      <th>Total Elapse Time</th>
      <th>Elapse For Properties</th>
      <th>Elapse For Booking</th>
    </tr>

    <tr *ngFor="let otaAccount of bookingDotComAccount; let i = index" >
      <td style="text-align: left">{{otaAccount.propertyOwnerPersonEmail}}</td>
      <td style="text-align: left">{{otaAccount.bookingDotComEmail}}</td>
      <td>{{otaAccount.numberOfProperties}}</td>
      <td>{{utilService.formatDateTime(otaAccount.lastSyncDatetime)}}</td>
      <td>{{otaAccount.lastSyncElapseTime}}</td>
      <td>{{otaAccount.lastSyncElapseTimeForProperty}}</td>
      <td>{{otaAccount.lastSyncElapseTimeForBooking}}</td>
    </tr>

  </table>

</div>
