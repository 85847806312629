import { Component, OnInit } from '@angular/core';
import {UsersService} from "../../core/services/users.service";
import {YaagoAirbnbCorporateOwnerError} from "../../model/YaagoAirbnbCorporateOwnerError";
import {UtilService} from "../../core/services/UtilService";
import {AirbnbAccount} from "../../model/AdminCorporateDetailsDto";

@Component({
  selector: 'app-m8-airbnb-error',
  templateUrl: './m8-airbnb-error.component.html',
  styleUrls: ['./m8-airbnb-error.component.scss']
})
export class M8AirbnbErrorComponent implements OnInit {

  yaagoAirbnbCorporateOwnerErrorList: YaagoAirbnbCorporateOwnerError[] = [];
  airbnbAccounts: AirbnbAccount[] = [];
  airbnbAccounts_NewAccount: AirbnbAccount[] = [];
  airbnbAccounts_NotFromServer: AirbnbAccount[] = [];
  airbnbAccounts_FromServer: AirbnbAccount[] = [];
  status: string = 'INVALID';

  constructor(private userService: UsersService,
              public utilService: UtilService) { }

  ngOnInit() {
    this.userService.getYaagoAirbnbCorporateOwnerError().subscribe( res => {
      this.yaagoAirbnbCorporateOwnerErrorList = res.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
      console.log(this.yaagoAirbnbCorporateOwnerErrorList);
    }, err => {

    });

    this.userService.getAirbnbAccountBystatus(this.status).subscribe( res => {
      this.airbnbAccounts = res;
    }, err => {

    });

    this.userService.getAirbnbAccountNotFromServer().subscribe( res => {
      this.airbnbAccounts_NotFromServer = res;
    }, err => {

    });

    this.userService.getAirbnbAccountFromServer().subscribe( res => {
      this.airbnbAccounts_FromServer = res;
    }, err => {

    });

    this.userService.getAirbnbAccountBystatus('NEW_ACCOUNT').subscribe( res => {
      this.airbnbAccounts_NewAccount = res;
    }, err => {

    });
  }

}
