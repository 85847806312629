
<div class="width-100 flex flex-column flex-justify-center flex-align-center">
  <h3>Permission blocks Management</h3>
  <div class="flex flex-row flex-align-center">
    <span class="Medium margin-right-12">⚠</span>
    <span>Edition of existing blocs is risky (proceed carefully)</span>
  </div>
</div>

<div class="width-90 flex flex-row margin-top-24 padding-32">
  <div class="width-25 flex flex-column " style="border-right: 1px solid #a7a7a7">
    <h4>Bloc Infos</h4>
    <div class="width-90">
      <mat-form-field class="width-100" >
        <mat-label>Bloc Name</mat-label>
        <input matInput  [(ngModel)]="currentBloc.name" (ngModelChange)="onBlocNameChange()"/>
      </mat-form-field>
      <mat-error *ngIf="nameExist">This bloc name already exist</mat-error>
    </div>

    <div class="width-90">
      <mat-form-field class="width-100" >
        <mat-label>Level (juste pour l'ordre d'affichage)</mat-label>
        <input matInput  [(ngModel)]="currentBloc.level"  type="number"/>
      </mat-form-field>
    </div>

    <div class="width-90 margin-top-24">
      <mat-form-field  class="width-100">
        <mat-label>Bloc Tag (only corporate having this tag will see the bloc) </mat-label>
        <input matInput [(ngModel)]="currentBloc.tag" (ngModelChange)="onBlocTagChange()"/>
      </mat-form-field>
      <mat-error *ngIf="tagExist">This bloc tag already exist</mat-error>
    </div>
  </div>


  <div class="width-75 flex flex-column padding-left-24">
    <h4>Bloc's Permission list</h4>
    <div class="flex flex-row">
      <div class="margin-right-24 width-25">
        <mat-form-field  class="width-100">
          <mat-label>Permission Item Name</mat-label>
          <input matInput [(ngModel)]="permissionItem.name" (ngModelChange)="onPermissionNameChange()"/>
        </mat-form-field>
        <mat-error *ngIf="permissionNameExist">This permission name already exist</mat-error>
      </div>

      <div class="margin-right-24 width-50">
        <mat-form-field  class="width-100">
          <mat-label>Linked To [comma separated] (format: permissionName) </mat-label>
          <input matInput [(ngModel)]="currentLinkedToCommSeparated" (ngModelChange)="onLinkedToChange()"/>
        </mat-form-field>
        <mat-error *ngIf="linkedToNotExist">One of the elements does not exist yet</mat-error>
      </div>
      <img class="clickable margin-left-12" width="26" (click)="addPermission()" src="assets/icons/plus.svg" alt="picto">
    </div>
    <div class="flex flex-row ">
      <div *ngFor="let item of currentBloc.permissionList; let i = index" class="item-container">
        <span class="roboto txt-color-grvogue Small">{{item.name}}</span>
        <mat-form-field class="width-90" >
          <mat-label>Level</mat-label>
          <input matInput  [(ngModel)]="item.level"  type="number"/>
        </mat-form-field>
        <span class="roboto XX-small margin-top-4" *ngIf="item.linkedTo && item.linkedTo.length>0">Linked To</span>
        <div class="flex flex-column" *ngFor="let lnk of item.linkedTo">
          <span class="roboto X-small">- {{lnk}}</span>
        </div>
        <img src="/assets/icons/delete.svg" alt="picto" class="delete-icon clickable" (click)="deletePermission(i)">
      </div>
    </div>
  </div>

</div>

<div class="width-100 flex flex-row flex-justify-center flex-align-end">
  <app-button  [type]="'primary'" [text]="'Enregistrer'"  (debounceClick)="saveBloc()" ></app-button>
</div>
<div class="width-90 flex flex-column margin-top-24 padding-32">
  <div class="width-100 flex flex-row flex-align-center padding-top-12 padding-bottom-12" style="border-bottom: 1px solid #d0d0d0"
  *ngFor="let bloc of allPermissionBlocs">
    <div class="flex flex-column">
      <div class="width-20 flex flex-row">
        <span>{{bloc.name}}</span>
        <span class="margin-left-8 txt-nowrap" *ngIf="bloc.tag">(tag : {{bloc.tag}})</span>
        <img class="margin-left-8 clickable" (click)="selectBlocToEdit(bloc)" src="/assets/icon-edit-recommendation.svg" alt="edit"/>
      </div>
      <span>{{bloc.level}}</span>
    </div>


    <div class="width-75 flex flex-row flex-wrap ">
      <div *ngFor="let item of bloc.permissionList" class="item-container">
        <span class="roboto txt-color-grvogue Small txt-nowrap">{{item.name}}</span>
        <span class="roboto XX-small margin-top-4">level : {{item.level}}</span>
        <span class="roboto XX-small margin-top-4" *ngIf="item.linkedTo && item.linkedTo.length>0">Linked To</span>
        <div class="flex flex-column" *ngFor="let lnk of item.linkedTo">
          <span class="roboto X-small txt-nowrap">- {{lnk}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<form [formGroup]="form">
  <div class="title">Permissions Blocks Management</div>
  <div class="global-disp">
    <div class="displayBlock">
      <div class="space-inputs">
        <p>Bloc name</p>
        <input type="text" formControlName="block" name="Bloc-name" value="" (change)="checkNameExistance()"/>
        <span *ngIf="nameExist">Name already exists...</span>
        <span *ngIf="nameEmpty">Block Name required...</span>

      </div>
      <div>
        <p>Permission</p>
        <input
          id="input"
          type="text"
          formControlName="permission"
          name="Permission"
          value=""
          required
        />

        <button class="add-button" type="button"  (click)="addPermission()"
          >
          +

        </button>

      </div>
     <span *ngIf="permissionNull" >Permission required</span>
      <span *ngIf="permissionExist">Permission exist </span>
      <div class="second-section" >
        <div class="box" *ngFor="let permission of tab; let index = index">
          <button
            type="button"
            class="delete"
            (click)="deletePermission(index)"
          >
            <span>&times;</span>
          </button>
          <div class="rec-permissions">{{ permission }}</div>
        </div>
      </div>

      <div class="submit-save">
        <div class="checkbox-div">
          <div class="form-check" *ngIf="false" >
            <input
              formControlName="enabled"
              id="check"
              type="checkbox"
              class="form-check-input ng-untouched ng-pristine ng-invalid"
            />
            <label class="form-check-label" >dispatch to all customers</label>
          </div>
        </div>
        <button *ngIf="!editMode" class="button-save" (click)="saveBlock()">save</button>
        <button *ngIf="editMode" class="button-save" (click)="saveEdit()">edit</button>
        <button *ngIf="editMode" class="button-save" (click)="cancelEdit()">cancel</button>
      </div>

      <table class="table">
        <tr>
          <th scope="col">Bloc name</th>
          <th scope="col">Permissions</th>
        </tr>

        <tr *ngFor="let permissionRow of allPermissionBlocs">
          <th scope="row">
            {{ permissionRow.name }}
            <div class="cursor" (click)="EditBlock(permissionRow)"  tabindex="0">
              <img
                class="img-tag"
                src="../assets/icon-edit-recommendation.svg"
                alt="edit"
              />
              <span style="margin-left: 5px">EDIT </span>
            </div>
          </th>
          <td>
            <div style="width: 100%; display: flex; flex-direction: row; margin-bottom: 10px; align-items: center"
              *ngFor="let permissionItem of permissionRow.permissionList; let index = index">
              <span>{{ permissionItem.name }}</span>
              <span style="margin-left: 4px" *ngIf="permissionItem.linkedTo && permissionItem.linkedTo.length > 0">
                 (linked to ==>
                    <ng-container *ngFor="let link of permissionItem.linkedTo">{{link}}</ng-container>
                )
              </span>
            </div>
          </td>
          <div></div>
        </tr>
        &lt;!&ndash; <tr>
        <th scope="row">static</th>
     <td >  static</td>

    </tr> &ndash;&gt;
      </table>
    </div>
  </div>
</form>-->
