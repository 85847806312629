import { Injectable } from '@angular/core';
import {ApiService} from '../http/api.service';
import {Observable} from 'rxjs';
import {Gurules} from '../../model/gurules';
import {GuideTranslation} from '../../model/guide';

@Injectable({
  providedIn: 'root'
})
export class GuideService {

  constructor(private api: ApiService) {}

  public currentGuideId  = '';

  // lang: string
  getGuRules(): Observable<Gurules[]> {
    return this.api.getAllGurules();
  }

  addGurule( gurule: Gurules): Observable<Gurules> {
    return this.api.addGurule( gurule);
  }

  updateGurule(guruleId: Gurules): Observable<Gurules> {
    return this.api.updateGurule(guruleId);
  }

  deleteGurule(guruleId: string): Observable<void> {
    return this.api.deleteGurule(guruleId);
  }


}
