export class Tags {

  id: string;
  category: Category;
  tags: Translations[];
  popular: boolean;

}

export class Translations {
  language: string;
  name: string;
}



export class Category {
  static readonly RESTAURANT = 'restaurant';
  static readonly BAKERY = 'bakery';
  static readonly COFFEE = 'coffee';
  static readonly PUB = 'pub';
  static readonly ACTIVITY = 'activity';
  static readonly SHOP = 'shop';
  static readonly MONUMENT = 'monument';
  static readonly OTHER = 'other';
  static readonly ALL = 'all';

  static values(): any {
    return {
      restaurant: Category.RESTAURANT,
      bakery: Category.BAKERY,
      activity: Category.ACTIVITY,
      coffee: Category.COFFEE,
      pub: Category.PUB,
      shop: Category.SHOP,
      monument: Category.MONUMENT,
      other: Category.OTHER,
      all: Category.ALL};
  }
}
