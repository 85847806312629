
export class I18n_Translation {

  id: string;
  yaagoApp: string;    // WEBAPP, BOOKLET, YAAGO.TEAM

  component: string;
  key: string;

  fr_Translation: string;

  en_Translation: string;

  es_Translation: string;

  pt_Translation: string;

  it_Translation: string;

  de_Translation: string;

  translationUpdatedDate: string;
}

export class I18n_TranslationDto {
  content: I18n_Translation[];
  totalElements: number;
  number: number;
}
export class I18n_TranslationSearch {
  pageNumber: number;
  size: number;
  textToSearch: string;
  yaagoApp: string;
}
