export class YaagoAcademyShort {
  id: string;
  section: string;
  subSection: string;
  title: string;
  videoUrl: string;
  displayOrder: number;
  language: string;
  createdDate: string;
  disabled: boolean;
}
