<div class="width-100 padding-32">
  <div class="profile-title"> Gestion des roles</div>


  <div class="loader" style="margin-top: 24px;" *ngIf="loading">
    <img  src="../assets/loading.svg" alt="picto" class="margin-top-24 rotate-loader" >
  </div>
  <!--<div class="flex flex-column flex-align-center flex-justify-center" *ngIf="noCorporate">
    <span class="mcqueen Medium margin-bottom-32 margin-top-32"> {{'role-management.no-corporate' | translate }}</span>
    <span class="roboto Small txt-color-scorpion">{{'role-management.no-corp-description' | translate }}</span>
    <span class="roboto X-small txt-color-dimgray">
      {{'role-management.contact-support' | translate }}
      <a class="txt-color-grvogue underline" href="" target="_blank">support</a>
    </span>
  </div>-->

  <div class="width-100  padding-16 flex flex-column" *ngIf="!loading">
    <div class="flex flex-row flex-align-center flex-space-between">
      <span class="mcqueen Medium">Nouveau Role</span>
    </div>
    <div class="flex flex-row flex-align-center flex-space-between">
      <div class="flex flex-row">
        <mat-form-field class="margin-right-12">
          <mat-label>displayable name </mat-label>
          <input matInput [(ngModel)]="roleToCreate.displayableName" (ngModelChange)="onChangeDisplayableName()"/>
        </mat-form-field>
        <mat-form-field class="margin-right-12">
          <mat-label>Identifiant </mat-label>
          <input matInput [(ngModel)]="roleToCreate.uniqueName" (ngModelChange)="onChangeUniqueName()"/>
        </mat-form-field>
        <img class="clickable" width="25" (click)="addNewRole()" src="assets/icons/plus.svg" alt="picto">
      </div>


    </div>

    <div class="width-100 flex flex-row margin-top-24">
      <!--LEFT SECTION-->
      <div class="width-15 flex flex-column padding-4 divider-right">
        <!--ACTIVATED ROLES-->
        <div *ngFor="let role of activeRoles"  (click)="selectRole(role)"
             class="mcqueen bold Medium margin-top-8 margin-bottom-8 clickable {{role.id === currentSelectedRole?.id ? 'active padding-left-12' : ''}}">
          {{role.displayableName}}
        </div>
        <!--DEACTIVATED ROLES-->
        <ng-template [ngIf]="disabledRoles?.length > 0">
          <span class="mcqueen Small margin-top-24 margin-bottom-8">Roles désactivés</span>
          <div class="flex flex-row flex-align-center flex-space-between margin-top-8" *ngFor="let role of disabledRoles" >
            <span class="roboto Small bold txt-color-scorpion" > {{role.displayableName}} </span>
            <span class="roboto Small txt-color-dimgray underline clickable padding-right-8" (click)="reactivateRole(role)">re-activer</span>
          </div>
        </ng-template>
      </div>

      <!--RIGHT SECTION EDIT-->
      <div class="width-75 flex flex-column padding-left-16" *ngIf="currentSelectedRole">

        <!--ROLE-EDIT-NAME-ADD-PERMISSION-->
        <div class="flex flex-row flex-align-center flex-space-between">
          <div class="flex flex-row flex-align-center">
            <mat-form-field class="margin-right-12">
              <mat-label>displayable name </mat-label>
              <input matInput [(ngModel)]="currentSelectedRole.displayableName"/>
            </mat-form-field>
            <!--<mat-form-field>
              <mat-label>{{'role-management.unique-name' | translate }} </mat-label>
              <input matInput [(ngModel)]="currentSelectedRole.uniqueName" [disabled]="true"/>
            </mat-form-field>-->
          </div>
          <div class="flex flex-row flex-align-center" *ngIf="filteredPermissionBloc?.length > 0">
            <mat-form-field>
              <mat-select class="select-category" [placeholder]="'Séléctionner un bloc'"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="selectedPermissionBlocId">

                <mat-option *ngFor="let l of filteredPermissionBloc" [value]="l.id">
                  <p>{{l.name}}</p>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <img class="clickable margin-left-12" width="26" (click)="addPermissionBloc()" src="assets/icons/plus.svg" alt="picto">
          </div>
        </div>

        <!--ROLE-EDIT-NO-BLOC-IN-ROLE-->
        <div class="flex flex-column flex-align-center flex-justify-center" *ngIf="!currentSelectedRole.permissionBlocs || currentSelectedRole.permissionBlocs.length === 0">
          <span class="mcqueen Medium margin-bottom-32 margin-top-64"> Pas de Bloc!!</span>
          <span class="roboto Small txt-color-scorpion">Vous n'avez sélectionné aucun bloc de permission pour ce role</span>
        </div>

        <div class="flex flex-column bloc-container grey-scroll">
          <!--PERMISSION-BLOC-->
          <div class="flex flex-column" *ngFor="let bloc of currentSelectedRole.permissionBlocs; let i = index">
            <!--BLOC-HEADER-->
            <div class="bloc-header roboto bold Small margin-top-16">
              <span>{{bloc.name }}</span>
              <img src="assets/icons/delete.svg" alt="picto" class="clickable" (click)="removeBloc(bloc, i)">
            </div>
            <!--BLOC-PERMISSION-->
            <div class="flex flex-row flex-space-between flex-align-center padding-right-32 padding-left-32 margin-top-6"
                 *ngFor="let permItem of bloc.permissionList">
              <span>{{bloc.name + '-' + permItem.name}}</span>
              <!--<mat-checkbox  [(ngModel)]="bloc.permissionList[key]"></mat-checkbox>-->
              <mat-slide-toggle [(ngModel)]="permItem.enabled"  [ngModelOptions]="{standalone: true}" [disabled]="isParentDisabled(permItem, bloc)"></mat-slide-toggle>

            </div>
          </div>
        </div>


        <div class="flex flex-row flex-align-center flex-space-between">
          <div class="flex flex-row">
            <app-button [type]="'ternary'" [text]="'Dupliquer'" class="margin-left-16" (debounceClick)="duplicateRole()" ></app-button>
          </div>
          <div class="flex flex-row flex-align-center">
            <mat-checkbox class="check-inactive" *ngIf="currentSelectedRole?.id" [(ngModel)]="dispatchActions">dispatcher à tout nos clients</mat-checkbox>

            <app-button  class="margin-left-16" [type]="'ternary'" [text]="'Désactiver'" (debounceClick)="deactivateRole()" ></app-button>
            <app-button  class="margin-left-16" [type]="'primary'" [text]="'Enregistrer'"  (debounceClick)="saveRoleChanges()" ></app-button>
          </div>

        </div>
      </div>
    </div>

  </div>

</div>
