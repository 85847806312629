<div class="y3-margin-top-20"></div>
<div *ngIf="showLoading">
  <div class="m0-loader" style="margin-top: 24px;" *ngIf="showLoading">
    <img  src="../assets/loading.svg" alt="picto" class="margin-top-24 m0-rotate-loader" >
  </div>
</div>

<div *ngIf="!showLoading" (keypress)="checkKeyPress($event)">

  <div class="y3-flex-row y3-flex-align-center y3-margin-bottom-8 y3-margin-left-8" >
    <div class="y3-flex-row y3-margin-right-8 button-notselected" [ngClass]="{'button-selected' : currentYaagoApp==='WEBAPP'  }" (click)="currentYaagoApp='WEBAPP'; refreshProcedure()">
      WEBAPP
    </div>
  <div class="y3-flex-row y3-margin-right-8 button-notselected" (click)="currentYaagoApp='YAAGO.TEAM'; refreshProcedure()" [ngClass]="{'button-selected' : currentYaagoApp==='YAAGO.TEAM'  }">
      YAAGO.TEAM
    </div>
    <div class="y3-flex-row y3-margin-right-8 button-notselected" (click)="currentYaagoApp='BOOKLET'; refreshProcedure()" [ngClass]="{'button-selected' : currentYaagoApp==='BOOKLET'  }">
      BOOKLET
    </div>
    <div class="y3-flex-row y3-margin-right-32 ">
      <div>Show French</div>
      <input [type]="'checkbox'" [checked]="showFrench" (click)="show('french')" >
    </div>
    <div class="y3-flex-row y3-margin-right-32 ">
      <div>Show English</div>
      <input [type]="'checkbox'" [checked]="showEnglish" (click)="show('english')" >
    </div>
    <div class="y3-flex-row y3-margin-right-32 ">
      <div>Show Spanish</div>
      <input [type]="'checkbox'" [checked]="showSpanish" (click)="show('spanish')" >
    </div>
    <div class="y3-flex-row y3-margin-right-32 ">
      <div>Show German</div>
      <input [type]="'checkbox'" [checked]="showGerman" (click)="show('german')" >
    </div>
    <div class="y3-flex-row y3-margin-right-32 ">
      <div>Show Italian</div>
      <input [type]="'checkbox'" [checked]="showItalian" (click)="show('italian')" >
    </div>
    <div class="y3-flex-row y3-margin-right-32 ">
      <div>Show Portuguese</div>
      <input [type]="'checkbox'" [checked]="showPortuguese" (click)="show('portuguese')" >
    </div>
  </div>

  <div class="y3-flex-row y3-flex-justify-space-between y3-flex-align-center " >

    <div style="position: relative; flex: 1; margin-right: 24px; width: calc(50% - 64px)" class="y3-flex-row">
      <input class="y3-search-text y3-padding-right-32 y3-padding-left-32 m0-search-bar"  [(ngModel)]="textToSearch" type="text">
      <div class="y3-margin-left-8">
        <app-button [type]="'secondary-x-small'" [text]="'Search'" (debounceClick)="pagination.pageNumber = 0; refreshProcedure()"></app-button>
      </div>
    </div>


    <div class=" y3-flex-row y3-flex-align-center y3-margin-right-16">
      <div class="flex-column">
        <div  *ngIf="!(pagination.first && pagination.last)" class="y3-margin-right-8 y3-X-small">{{pagination.pageNumber+1}} / {{pagination.totalPages}} Page(s)</div>
        <div class="y3-X-small"> {{pagination.totalElements}} Translation(s)</div>
      </div>
      <img  *ngIf="!(pagination.first && pagination.last)" (click)="previousPage()" class="m0-logo-nav y3-clickable" src="{{pagination.first?'assets/icons/left-chevron-gray.png' : 'assets/icons/left-chevron.png'}}">
      <img  *ngIf="!(pagination.first && pagination.last)" (click)="nextPage()" class="m0-logo-nav y3-clickable " src="{{pagination.last?'assets/icons/right-chevron-gray.png' : 'assets/icons/right-chevron.png'}}">
    </div>
  </div>
</div>

<div class="y3-margin-top-8">


  <table style="margin-top: 24px; width: 97%">
    <tr>
      <th style="text-align: left; width: 150px" class="y3-padding-right-32">Component</th>
      <th style="text-align: left" class="y3-padding-right-32">Key</th>
      <th *ngIf="showFrench" style="text-align: left" class="y3-padding-right-32">French</th>
      <th *ngIf="showEnglish" style="text-align: left" class="y3-padding-right-32">English</th>
      <th *ngIf="showSpanish" style="text-align: left" class="y3-padding-right-32">Espagnol</th>
      <th *ngIf="showItalian" style="text-align: left" class="y3-padding-right-32">Italien</th>
      <th *ngIf="showPortuguese" style="text-align: left" class="y3-padding-right-32">Portugais</th>
      <th *ngIf="showGerman" style="text-align: left" class="y3-padding-right-32">German</th>
      <th></th>
    </tr>

    <tr *ngFor="let translation of i18nTranslations; let i = index" >

      <td class="y3-Small">{{translation.component}}</td>
      <td class="y3-Small">{{translation.key}}</td>
      <td *ngIf="showFrench" class="y3-Small">
        <textarea style="width: 95%" rows="4" class="y3-input-text-small y3-roboto" [(ngModel)]="translation.fr_Translation"></textarea>
      </td>
      <td *ngIf="showEnglish" class="y3-Small"  [ngClass]="{'class-red' : translation.en_Translation === translation.fr_Translation }  ">
        <textarea style="width: 95%" rows="4" class="y3-input-text-small y3-roboto" [(ngModel)]="translation.en_Translation"></textarea>
      </td>
      <td *ngIf="showSpanish" class="y3-Small "  [ngClass]="{'class-red' : translation.es_Translation === translation.fr_Translation }  ">
        <textarea style="width: 95%" rows="4" class="y3-roboto y3-input-text-small" [(ngModel)]="translation.es_Translation"></textarea>

      </td>
      <td *ngIf="showItalian" class="y3-Small "  [ngClass]="{'class-red' : translation.it_Translation === translation.fr_Translation }  ">
        <textarea style="width: 95%" rows="4" class="y3-input-text-small y3-roboto" [(ngModel)]="translation.it_Translation"></textarea>
      </td>
      <td *ngIf="showPortuguese" class="y3-Small "  [ngClass]="{'class-red' : translation.pt_Translation === translation.fr_Translation }  ">
        <textarea style="width: 95%" rows="4" class="y3-input-text-small y3-roboto"  [(ngModel)]="translation.pt_Translation"></textarea>
      </td>
      <td *ngIf="showGerman" class="y3-Small "  [ngClass]="{'class-red' : translation.de_Translation === translation.fr_Translation }  ">
        <textarea style="width: 95%" rows="4" class="y3-input-text-small y3-roboto" [(ngModel)]="translation.de_Translation"></textarea>
      </td>

      <td style="text-align: center; cursor: pointer">
        <mat-icon style="color: darkseagreen" (click)="updateTranslation(translation)">check_circle</mat-icon>
      </td>

    </tr>
  </table>

</div>

