import { HttpClient, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../interceptors/auth.interceptors";
import * as i3 from "./conf.service";
export class FunFactService {
    constructor(http, authInterceptor, confService) {
        this.http = http;
        this.authInterceptor = authInterceptor;
        this.confService = confService;
    }
    formatErrors(error) {
        return throwError(error.error);
    }
    getTokenHeaders() {
        return { headers: new HttpHeaders({ Authorization: this.authInterceptor.getAdminKey() }) };
    }
    getFunFact() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/funfact', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getFunFactMonthly() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/funfact/monthly', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getMrrCustomer() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/funfact/mrr', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getAirbnbOtaAccount() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/ota-account/airbnb', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getBookingDotComAccount() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/ota-account/booking-dot-com', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
}
FunFactService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FunFactService_Factory() { return new FunFactService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthInterceptor), i0.ɵɵinject(i3.ConfService)); }, token: FunFactService, providedIn: "root" });
