import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../services/AuthService';
import {catchError} from 'rxjs/operators';
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  adminKey: string = "";

  constructor(private router : Router) {
  }

  public setAdminKey(key: string) {
    localStorage.setItem('adminKey', key);
    //this.adminKey = key;
  }
  public getAdminKey(): string {
    let adminKey = localStorage.getItem('adminKey');
    return adminKey == null ? '' : adminKey;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // @ts-ignore
    return next.handle(req).pipe(catchError(e => {
      if(e instanceof HttpErrorResponse){
        if(e.status === 401){
          this.router.navigate(['../../'])
          alert('Your master key is not authorized, please re-login.');
          return
        } else {
          return throwError(e);
        }
      } else {
        return throwError(e);
      }

    }));

    // console.log('i am in the interceptor')
    let request = req;

    // If token add bearer.
    // request = this.addToken(req);

    // Pass on the cloned request instead of the original request.
    return next.handle(request).pipe(catchError(error => {
      console.log('i am in the interceptor22')
      if (error instanceof HttpErrorResponse) {
        console.log('i am error in interceptor')
        const apiUrlIndex = req.url.indexOf('/api');
        const url = req.url.substring(apiUrlIndex);
        if (error.status === 401) {
          console.log('i am 401111')
          // return this.handle401Error(request, next);
          return throwError('400001111');
        } else if (error.status === 0) {
          // Server down
          // this.authService.logoutWithoutCheck();
          // this.snackbar.push(new SnackbarMessage(this.translate.instant('auth.server-down'), 'error'));
          return throwError(error);
        } else {
          return throwError(error);
        }
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: this.adminKey
      }
    });
  }

}
