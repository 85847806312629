import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PermissionService } from "src/app/core/services/permission.service";
import {PermissionBloc, PermissionItem} from "src/app/model/PlatformRole";
import {cloneDeep} from 'lodash';

@Component({
  selector: "app-permissions",
  templateUrl: "./permissions.component.html",
  styleUrls: ["./permissions.component.scss"],
})
export class PermissionsComponent implements OnInit {
  allPermissionBlocs: PermissionBloc[] = [];

  currentBloc: PermissionBloc = new PermissionBloc();
  tagExist = false;
  nameExist = false;
  permissionItem = new PermissionItem();
  permissionItemLinkedTo: string[] = [];
  permissionNameExist = false;
  currentLinkedToCommSeparated: string = "";
  linkedToNotExist = false;
  constructor(
    private router: Router,
    private permissionService: PermissionService,
  ) {}
  ngOnInit() {

    this.getPermissions();
  }

  addPermission() {
    if (this.permissionNameExist) {
      alert("permission name already exists");
      return;
    }
    if (this.linkedToNotExist) {
      alert("One of linked to elements is not existing yet");
      return;
    }
    if (!this.permissionItem.name) {
      alert("Permission name is mandatory");
      return;
    }
    this.permissionItem.linkedTo = cloneDeep(this.permissionItemLinkedTo);
    this.permissionItemLinkedTo = [];
    this.currentLinkedToCommSeparated = "";
    if (!this.currentBloc.permissionList) {
      this.currentBloc.permissionList = [];
    }
    this.currentBloc.permissionList.push(cloneDeep(this.permissionItem));
    this.permissionItem =  new PermissionItem();
  }
  deletePermission(index) {
    this.currentBloc.permissionList.splice(index, 1);
  }

  getPermissions() {
    this.permissionService.getPermissions().subscribe((res) => {
      this.allPermissionBlocs = res;
      this.allPermissionBlocs.forEach(item => {
        if (item.permissionList && item.permissionList.length > 0) {
          item.permissionList.sort((a, b) => a.level - b.level);
        }
      });
      this.allPermissionBlocs.sort((a, b) => a.level - b.level);
    });
  }

  onBlocNameChange() {
    if (this.currentBloc.name) {
      this.currentBloc.name = this.currentBloc.name.toLowerCase();
    }

    const index = this.allPermissionBlocs.findIndex(item => item.id !== this.currentBloc.id && item.name === this.currentBloc.name);

    this.nameExist = index > -1;
  }

  onBlocTagChange() {
    if (this.currentBloc.tag) {
      this.currentBloc.tag = this.currentBloc.tag.toLowerCase();
    }
    const index = this.allPermissionBlocs.findIndex(item => item.id !== this.currentBloc.id && item.tag === this.currentBloc.tag);

    this.tagExist = index > -1;
  }

  onPermissionNameChange() {
    if (this.permissionItem.name) {
      this.permissionItem.name = this.permissionItem.name.toLowerCase();
    }
    if (!this.currentBloc.permissionList || this.currentBloc.permissionList.length === 0) {
      this.permissionNameExist = false;
    }
    const index = this.currentBloc.permissionList.findIndex(item => item.name === this.permissionItem.name);

    this.permissionNameExist = index > -1;
  }

  onLinkedToChange() {
    this.permissionItemLinkedTo = [];
    // this.linkedToNotExist = false;
    if (this.currentLinkedToCommSeparated) {
      this.currentLinkedToCommSeparated = this.currentLinkedToCommSeparated.toLowerCase().replace(/\s/g, '');
      this.permissionItemLinkedTo = this.currentLinkedToCommSeparated.split(',');
    }

    const intersection = this.currentBloc.permissionList.filter(item => this.permissionItemLinkedTo.includes(item.name));

    this.linkedToNotExist = intersection.length !== this.permissionItemLinkedTo.length;
  }

  selectBlocToEdit(bloc: PermissionBloc) {
    this.currentBloc = cloneDeep(bloc);
  }

  saveBloc() {
    if (this.nameExist || this.tagExist) {
      alert('Existing errors');
      return;
    }
    if (!this.currentBloc.name) {
      alert('Name is mandatory');
      return;
    }
    if (!this.currentBloc.permissionList || this.currentBloc.permissionList.length === 0) {
      alert('Permission list is empty');
      return;
    }

    if (this.currentBloc.id) {
      this.permissionService.updatePermission(this.currentBloc)
        .subscribe(data => {
          this.currentBloc = new PermissionBloc();
          this.tagExist = false;
          this.nameExist = false;
          this.permissionItem = new PermissionItem();
          this.permissionItemLinkedTo = [];
          this.permissionNameExist = false;
          this.currentLinkedToCommSeparated = "";
          this.linkedToNotExist = false;
          this.getPermissions(); // To remove this and add return object
        }, error => {
          alert(error);
        });
    } else {
      this.permissionService.addPermission(this.currentBloc)
        .subscribe(data => {
          this.currentBloc = new PermissionBloc();
          this.tagExist = false;
          this.nameExist = false;
          this.permissionItem = new PermissionItem();
          this.permissionItemLinkedTo = [];
          this.permissionNameExist = false;
          this.currentLinkedToCommSeparated = "";
          this.linkedToNotExist = false;
          this.getPermissions(); // To remove this and add return object
        }, error => {
          alert(error);
        });
    }
  }
}
