import { Injectable } from '@angular/core';
import { AuthInterceptor } from '../interceptors/auth.interceptors';
import { ConfService } from './conf.service';
import { PermissionBloc } from 'src/app/model/PlatformRole';
import { ApiService } from '../http/api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {


  constructor(private api: ApiService,
              private authInterceptor: AuthInterceptor,
              private confService: ConfService) {
  }


  addPermission(permissionBloc: PermissionBloc) {
    return this.api.addPermission(permissionBloc);
  }
  getPermissions() {
    return this.api.getPermissions();
  }
  updatePermission(permissionBloc: PermissionBloc) {
    return this.api.updatePermission(permissionBloc);
  }
}

