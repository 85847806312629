import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services/conf.service";
import * as i3 from "../interceptors/auth.interceptors";
export class ApiService {
    constructor(http, confService, authInterceptor) {
        this.http = http;
        this.confService = confService;
        this.authInterceptor = authInterceptor;
    }
    static getHeaders() {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    }
    static getOptionsWithParams(httpParams) {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    }
    static getHeadersForUpload() {
        return { headers: new HttpHeaders() };
    }
    getTokenHeaders() {
        return { headers: new HttpHeaders({ Authorization: this.authInterceptor.getAdminKey() }) };
    }
    formatErrors(error) {
        return throwError(error.error);
    }
    // GURULES
    getAllGurules() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/gurules', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    addGurule(gurule) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/gurules', gurule, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    updateGurule(gurule) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/gurules', gurule, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    deleteGurule(guruleId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/admin/gurules/' + guruleId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getNext10Bookings(propertyId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/propertybooking/' + propertyId, this.getTokenHeaders()).pipe(catchError(this.formatErrors));
    }
    getPropertyPoi() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/property/properties-poi', this.getTokenHeaders()).pipe(catchError(this.formatErrors));
    }
    // TAGS
    getTags() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    addTags(tags) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags', tags, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    updateTags(tags) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags', tags, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    deleteTags(TagsId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags/' + TagsId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    bulkMissingTranslations() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags/missing-translations', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    // PERMISSION
    addPermission(permission) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/permissionBloc/', permission, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getPermissions() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/permissionBloc', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    updatePermission(permissionBloc) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/permissionBloc', permissionBloc, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    // ROLES
    getAllRoles() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles', this.getTokenHeaders());
    }
    createNewRole(role) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles', role, this.getTokenHeaders());
    }
    updateRole(role, dispatch) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/' + role.id + '/' + dispatch, role, this.getTokenHeaders());
    }
    duplicateRole(role) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/duplicate/' + role.id, this.getTokenHeaders());
    }
    enableRole(role, enable, dispatch) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/' + role.id + '/' + enable + '/' + dispatch, this.getTokenHeaders());
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfService), i0.ɵɵinject(i3.AuthInterceptor)); }, token: ApiService, providedIn: "root" });
