
export class PlatformRole {
   id: string;
   displayableName: string;
   uniqueName: string;
   yaagoRole = true;
   enabled = true;
   permissionBlocs: PermissionBloc[];
  // when the role is inside corporate
  changedByCorporate;
}

export  class PermissionBloc {
  id: string;
  name: string;
  tag: string;
  enabled: boolean;
  level: number;
  permissionList: PermissionItem[] = [];
}

export class PermissionItem {
  name: string;
  level: number;
  enabled: boolean;
  linkedTo: string[];
}
