<div class="y3-margin-top-24 y3-padding-left-16 y3-padding-right-16">

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">YAAGO ACADEMY SHORT LIST</div>

  <table  style="margin-top: 8px; text-align: left">
    <tr>
      <th style="text-align: left">Section </th>
      <!--<th style="text-align: left">SubSection</th>-->
      <th style="text-align: left">Title</th>
      <th style="text-align: left">videoUrl</th>
      <th style="text-align: left">DisplayOrder</th>
      <th style="text-align: left">Language</th>
      <th>Edit</th>
    </tr>
    <tr *ngFor="let short of yaagoAcademyList">
      <td style="text-align: left">
        {{short.section}}
        </td>
      <!--<td style="text-align: left">{{short.subSection}}</td>-->
      <td style="text-align: left; font-weight: bold">{{short.title}}</td>
      <td style="text-align: left">{{short.videoUrl}}</td>
      <td style="text-align: left">{{short.displayOrder}}</td>
      <td style="text-align: left">{{short.language}}</td>
      <td class="y3-clickable m2-button" (click)="currentYaagoAcademy = short">
        EDIT
      </td>
    </tr>
  </table>

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; margin-bottom: 8px; color: black">CREATION OF NEW SHORT</div>



  <div class="flex-column " style="width: 500px;">

    <div *ngIf="currentYaagoAcademy.id"  class="y3-clickable m2-button y3-margin-bottom-6" (click)="createNew()">
      RESET FOR A NEW ONE
    </div>

    <div class="flex-row y3-flex-align-center y3-margin-bottom-6 y3-flex-justify-space-between" >
      <div>Section</div>
      <div>
        <input style="width: 400px;" class="y3-input-text-small y3-margin-right-4" [(ngModel)]="currentYaagoAcademy.section"/>
      </div>
    </div>
    <div class="flex-row y3-flex-align-center  y3-margin-bottom-6 y3-flex-justify-space-between">
      <div>Title</div>
      <input style="width: 400px;" class="y3-input-text-small y3-margin-right-4" [(ngModel)]="currentYaagoAcademy.title"/>
    </div>
    <div class="flex-row y3-flex-align-center  y3-margin-bottom-6 y3-flex-justify-space-between">
      <div>VideoUrl</div>
      <input style="width: 400px;" class="y3-input-text-small y3-margin-right-4" [(ngModel)]="currentYaagoAcademy.videoUrl"/>
    </div>
    <div class="flex-row y3-flex-align-center  y3-margin-bottom-6 y3-flex-justify-space-between">
      <div>Language</div>
      <input style="width: 400px;" class="y3-input-text-small y3-margin-right-4" [(ngModel)]="currentYaagoAcademy.language"/>
    </div>
    <div class="flex-row y3-flex-align-center  y3-margin-bottom-6 y3-flex-justify-space-between">
      <div>Display Order</div>
      <input style="width: 400px;" class="y3-input-text-small y3-margin-right-4" [(ngModel)]="currentYaagoAcademy.displayOrder"/>
    </div>
    <div *ngIf="!currentYaagoAcademy.id"  class="y3-clickable m2-button" (click)="validateYaagoAcademyShort()">
      CREATE
    </div>
    <div *ngIf="currentYaagoAcademy.id"  class="y3-clickable m2-button" (click)="validateYaagoAcademyShort()">
      UPDATE
    </div>
  </div>

</div>

