import * as i0 from "@angular/core";
import * as i1 from "../http/api.service";
import * as i2 from "../interceptors/auth.interceptors";
import * as i3 from "./conf.service";
export class PermissionService {
    constructor(api, authInterceptor, confService) {
        this.api = api;
        this.authInterceptor = authInterceptor;
        this.confService = confService;
    }
    addPermission(permissionBloc) {
        return this.api.addPermission(permissionBloc);
    }
    getPermissions() {
        return this.api.getPermissions();
    }
    updatePermission(permissionBloc) {
        return this.api.updatePermission(permissionBloc);
    }
}
PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.AuthInterceptor), i0.ɵɵinject(i3.ConfService)); }, token: PermissionService, providedIn: "root" });
