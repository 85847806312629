import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../core/http/api.service";
import {PropertyPoi} from "../../model/PropertyPoi";
import {Poi} from "../../model/poi";

@Component({
  selector: 'app-property-map',
  templateUrl: './property-map.component.html',
  styleUrls: ['./property-map.component.scss']
})
export class PropertyMapComponent implements OnInit {

  centerOfTheMap: Poi = new Poi();
  propertyPois : PropertyPoi[] = [];
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.centerOfTheMap.x = 2.352221;
    this.centerOfTheMap.y = 48.856614;

    this.apiService.getPropertyPoi().subscribe( ps => {
     this.propertyPois = ps;
    });
  }

}
