import {Injectable} from '@angular/core';
import {ConfService} from '../services/conf.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Gurules} from '../../model/gurules';
import {Observable, throwError} from 'rxjs';
import {AuthInterceptor} from '../interceptors/auth.interceptors';
import {catchError, flatMap} from 'rxjs/operators';
import {PropertyBooking} from '../../model/property-booking';
import {Tags} from '../../model/tags';
import {PermissionBloc, PlatformRole} from 'src/app/model/PlatformRole';
import {PropertyPoi} from "../../model/PropertyPoi";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private confService: ConfService,
    private authInterceptor: AuthInterceptor
  ) {
  }


  static getHeaders(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders({'Content-Type': 'application/json'})};
  }

  static getOptionsWithParams(httpParams: HttpParams) {
    return {headers: new HttpHeaders({'Content-Type': 'application/json'}), params: httpParams};
  }

  static getHeadersForUpload(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders()};
  }

  public getTokenHeaders(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders({Authorization: this.authInterceptor.getAdminKey()} )};
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  // GURULES

  getAllGurules(): Observable<Gurules[]> {
    return this.http.get<Gurules[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/gurules', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  addGurule(gurule: Gurules): Observable<Gurules> {
    return this.http.put<Gurules>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/gurules', gurule,  this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  updateGurule(gurule: Gurules): Observable<Gurules> {
    return this.http.post<Gurules>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/gurules', gurule, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  deleteGurule(guruleId: string): Observable<void> {
    return this.http.delete<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/gurules/' + guruleId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }


  getNext10Bookings(propertyId: string): Observable<PropertyBooking[]> {
    return this.http.get<PropertyBooking[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/propertybooking/' + propertyId  ,
      this.getTokenHeaders()).pipe(catchError(this.formatErrors));
  }

  getPropertyPoi(): Observable<PropertyPoi[]> {
    return this.http.get<PropertyPoi[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/property/properties-poi',
      this.getTokenHeaders()).pipe(catchError(this.formatErrors));
  }


  // TAGS

  getTags(): Observable<Tags[]> {
    return this.http.get<Tags[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  addTags(tags: Tags): Observable<Tags> {
    return this.http.put<Tags>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags', tags,  this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  updateTags(tags: Tags): Observable<Tags> {
    return this.http.post<Tags>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags', tags, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  deleteTags(TagsId: string): Observable<void> {
    return this.http.delete<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags/' + TagsId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  bulkMissingTranslations(): Observable<void> {
    return this.http.get<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/tags/missing-translations', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }



  // PERMISSION

  addPermission(permission: PermissionBloc) {
    return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/permissionBloc/', permission, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  getPermissions() {
    return this.http.get<PermissionBloc[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/permissionBloc', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  updatePermission(permissionBloc: PermissionBloc) {
    return this.http.put<PermissionBloc>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/permissionBloc',
      permissionBloc, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }


  // ROLES
  getAllRoles(): Observable<PlatformRole[]> {
    return this.http.get<PlatformRole[]>
    (this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles', this.getTokenHeaders());
  }
  createNewRole(role: PlatformRole): Observable<PlatformRole> {
      return this.http.post<PlatformRole>
      (this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles', role, this.getTokenHeaders());
  }

  updateRole(role: PlatformRole, dispatch: boolean): Observable<PlatformRole> {
      return this.http.put<PlatformRole>
      (this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/' + role.id + '/' + dispatch, role, this.getTokenHeaders());
  }
  duplicateRole(role: PlatformRole): Observable<PlatformRole> {
      return this.http.get<PlatformRole>
      (this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/duplicate/' + role.id, this.getTokenHeaders());
  }
  enableRole(role: PlatformRole, enable: boolean, dispatch: boolean): Observable<PlatformRole> {
      return this.http.get<PlatformRole>(
        this.confService.getBackendApiEndPoint() + 'api-v2/admin/roles/' + role.id + '/' + enable + '/' + dispatch, this.getTokenHeaders());
  }


  /*deleteRole(corporateId: string, role: PlatformRole): Observable<void> {
      return this.http.delete<void>
      (this.confService.getBackendApiEndPoint() + 'api-v2/roles/' + corporateId + '/' + role.id, this.getTokenHeaders());
  }*/
}
