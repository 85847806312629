import {Injectable} from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfService {

  constructor(private config: ConfigService) {
  }

  getBackendApiEndPoint() {
    try {
      const { apiEndpoint } = environment
      //const url = apiEndpoint || this.config.getSettings('system.apiEndPoint');
      // const url =  "http://localhost:8080/";

      const url = "";

      if (url == null || url.trim() === '') {
        if (window.location.origin.indexOf("staging") > -1) {
          return window.location.origin.replace("admin.staging", "staging") + "/";
        } else if (window.location.origin.indexOf("demo") > -1) {
          return window.location.origin.replace("admin.demo", "demo") + "/";
        } else {
          return window.location.origin.replace("admin.", "app.") + "/";
        }
      } else {
        return url;
      }
    } catch (exception) {
      if (window.location.origin.indexOf("staging") > -1) {
        return window.location.origin.replace("admin.staging", "staging") + "/";
      } else if (window.location.origin.indexOf("demo") > -1) {
        return window.location.origin.replace("admin.demo", "demo") + "/";
      } else {
        return window.location.origin.replace("admin.", "app.") + "/";
      }
    }
  }

}
