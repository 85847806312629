  import * as moment from "moment/moment";
import {Injectable} from "@angular/core";
  import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private translateService: TranslateService) {
  }

  formatDateOnly(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('DD/MM/YYYY');
    }
    return ret;
  }

  formatDateTime(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      ret = moment(date).format('DD/MM/YYYY - HH:mm');
    }
    return ret;
  }
  difDays(date: string) {
    let ret = 0;
    if (date != null && date !== '') {
      ret = moment(new Date()).diff(moment(date), 'days');
    }
    return Math.abs(ret);
  }

  noAbsdifDays(date: string) {
    let ret = 0;
    if (date != null && date !== '') {
      ret = moment(new Date()).diff(moment(date), 'days');
    }
    return ret;
  }

  difMinutes(date: string) {
    let ret = 0;
    if (date != null && date !== '') {
      ret = moment(new Date()).diff(moment(date), 'minutes');
    }
    return Math.abs(ret);
  }

  getFirstLetterString(firstName: string, lastName: string, email: string) {
    if (firstName) {
      return firstName.substr(0, 1);
    }
    if (lastName) {
      return lastName.substr(0, 1);
    }
    if (email) {
      return email.substr(0, 1);
    } else {
      return "U";
    }
  }
}
