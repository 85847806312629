import {MatButtonModule} from "@angular/material/button";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSelectModule} from "@angular/material/select";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { MatDialogModule } from '@angular/material';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {MatMenuModule} from "@angular/material/menu";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TagsComponent } from './pages/tags/tags.component';
import { PermissionsComponent } from './pages/permissions/permissions.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RolesComponent } from './pages/roles/roles.component';
import {ButtonComponent} from "./components/button/button.component";
import {DebounceClickDirective} from "./directives/debounce-click.directive";

import {LeafletMapComponent} from "./components/leaflet-map/leaflet-map.component";
import { PropertyMapComponent } from './pages/property-map/property-map.component';

import { M0UsersListComponent } from './pages/m0-users-list/m0-users-list.component';
import { M1UserDetailsComponent } from './pages/m1-user-details/m1-user-details.component';
import { M01UsersFiltersComponent } from './pages/m0-users-list/m01-users-filters/m01-users-filters.component';
import { M3HomeStatsComponent } from './pages/m3-home-stats/m3-home-stats.component';
import { M4YaagoPerfComponent } from './pages/m4-yaago-perf/m4-yaago-perf.component';
import { M5OtaAccountsComponent } from './pages/m5-ota-accounts/m5-ota-accounts.component';
import { M6TranslationComponent } from './pages/m6-translation/m6-translation.component';
import {AuthInterceptor} from "./core/interceptors/auth.interceptors";
import { M7AmbassadorComponent } from './pages/m7-ambassador/m7-ambassador.component';
// import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import { M8AirbnbErrorComponent } from './pages/m8-airbnb-error/m8-airbnb-error.component';
import { M9DashboardTechComponent } from './pages/m9-dashboard-tech/m9-dashboard-tech.component';
import { M10YaagoAcademyComponent } from './pages/m10-yaago-academy/m10-yaago-academy.component';
import { M11YaagoPmsRevComponent } from './pages/m11-yaago-pms-rev/m11-yaago-pms-rev.component';
import { M12YaagoUpsellsComponent } from './pages/m12-yaago-upsells/m12-yaago-upsells.component';




@NgModule({
  declarations: [
    AppComponent,
    ButtonComponent,
    DebounceClickDirective,
    NavbarComponent,
    LeafletMapComponent,

    M0UsersListComponent,
    M01UsersFiltersComponent,
    M1UserDetailsComponent,
    M3HomeStatsComponent,
    M4YaagoPerfComponent,
    M5OtaAccountsComponent,
    M6TranslationComponent,
    PermissionsComponent,
    PropertyMapComponent,
    RolesComponent,
    TagsComponent,
    M7AmbassadorComponent,
    M8AirbnbErrorComponent,
    M9DashboardTechComponent,
    M10YaagoAcademyComponent,
    M11YaagoPmsRevComponent,
    M12YaagoUpsellsComponent


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    MatDialogModule
   // NgMultiSelectDropDownModule.forRoot(),
  ],
  // providers: [
  // ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },

  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: AuthInterceptor,
  //   multi: true
  // },
],
  entryComponents: [M01UsersFiltersComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
