import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
export class HttpService {
    //adminKey: string;
    constructor() { }
    setAdminKey(key) {
        localStorage.setItem('adminKey', key);
    }
    getAdminKey() {
        let adminKey = localStorage.getItem('adminKey');
        return adminKey == null ? '' : adminKey;
    }
    static getHeaders() {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    }
    static getOptionsWithParams(httpParams) {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    }
    static getHeadersForUpload() {
        return { headers: new HttpHeaders() };
    }
    getTokenHeaders() {
        return { headers: new HttpHeaders({ Authorization: this.getAdminKey() }) };
    }
    formatErrors(error) {
        return throwError(error);
    }
}
HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(); }, token: HttpService, providedIn: "root" });
