export class Guide {
  guideId: string;
  category: string;
  pictureUrls: string[];
  pdf: string[];
  // videoUrls: string[];
  accessibleOnlyDuringStay: boolean;
  translations: GuideTranslation[];
}

export class GuideTranslation {
  language: string;
  description: string;
  video: string;
  title: string;
  wifiSecurityProtocol: string;
  wifiHidden: boolean;
}


export class CategoryGuide {
  static readonly CHECKIN = 'check-in';
  static readonly CHECKOUT = 'check-out';
  static readonly ORIENTATION = 'orientation';
  static readonly RULES = 'rules';
  static readonly WIFI = 'wifi';
  static readonly PARKING = 'parking';
  static readonly APPLIANCES = 'appliances';
  static readonly TRASH = 'trash';
  static readonly HEATING = 'heating';
  static readonly OTHER = 'other';

  static values(): any {
    return {
      orientation: CategoryGuide.ORIENTATION,
      parking: CategoryGuide.PARKING,
      checkin: CategoryGuide.CHECKIN,

      wifi: CategoryGuide.WIFI,
      rules: CategoryGuide.RULES,
      other: CategoryGuide.OTHER,

      appliances: CategoryGuide.APPLIANCES,
      heating: CategoryGuide.HEATING,
      trash: CategoryGuide.TRASH,

      checkout: CategoryGuide.CHECKOUT
    };
  }
}

