<div class="y3-margin-top-24 y3-padding-left-16 y3-padding-right-16">

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">AMBASSADOR LIST</div>

  <table  style="margin-top: 8px; text-align: left">
    <tr>
      <th style="text-align: left">Ambassador </th>
      <th style="text-align: left">Date</th>
      <th style="text-align: left">Company Name</th>
      <th style="text-align: left">Firstname</th>
      <th style="text-align: left">Lastname</th>
      <th style="text-align: left">Phone</th>
      <th style="text-align: left">Email</th>
      <th style="text-align: left">City</th>
      <th style="text-align: left">Status</th>
    </tr>
    <tr *ngFor="let ambassador of yaagoAmbassadors">
      <td style="text-align: left">
      {{ambassador.ambassadorFirstName}}
      {{ambassador.ambassadorLastName}} - {{ambassador.ambassadorEmail}}</td>
      <td style="text-align: left">{{utilService.formatDateOnly(ambassador.creationDate)}}</td>
      <td style="text-align: left; font-weight: bold">{{ambassador.companyName}}</td>
      <td style="text-align: left">{{ambassador.firstName}}</td>
      <td style="text-align: left">{{ambassador.lastName}}</td>
      <td style="text-align: left">{{ambassador.phone}}</td>
      <td style="text-align: left">{{ambassador.email}}</td>
      <td style="text-align: left">{{ambassador.city}}</td>
      <td style="text-align: left; color: darkred" *ngIf="ambassador.status === 'expired'">{{ambassador.status}}</td>
      <td style="text-align: left; color: darkseagreen" *ngIf="ambassador.status === 'win'">{{ambassador.status}}</td>
      <td style="text-align: left; color: green" *ngIf="ambassador.status === 'paid'">{{ambassador.status}}</td>
      <td style="text-align: left; color: darkorange" *ngIf="ambassador.status === 'inprogress'">{{ambassador.status}}</td>
    </tr>
  </table>
</div>
