import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';

const googleTranslateApiURL = 'https://translation.googleapis.com/language/translate/v2';
const googleTranslateApiKey = window['googleApiKey']; // 'AIzaSyCS9qNVwqdSPoRv-aL-iMPCqayxeBIp-os';
const googleTranslateApiFormat = 'text';

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslateService {
  constructor(private http: HttpClient) { }
  public translate(q: string, target: string, source: string): Observable<string> {
    return this.http.get<string>(`${googleTranslateApiURL}?q=${q}&target=${target}&source=${source}&key=${googleTranslateApiKey}`, {});
  }
}
