export class PersonFilter {
  personSearch : string;
  corporateId : string;
  corporateOwner: boolean;
  sortingField: string;
  inTrial: Boolean;
  inTrialOver: Boolean;
  onBoarded: boolean | null;
  premium: Boolean;
  performance: Boolean;
  paidCustomer:Boolean;
  closedAccount: Boolean;
  nonOnBoarded: boolean | null;
  sortDirection : string;
  pageSize : number;
  pageNumber : number;

  hasSmily: boolean;
  hasAvantio: boolean;
  hasRentalReady: boolean;
  hasAirbnb: boolean;
  hasBooking: boolean;
  hasEviivo: boolean;
  hasSepteo: boolean;
  hasBeds24: boolean;
  hasSmoobu: boolean;
  hasGiteDeFrance: boolean;
  hasClf: boolean;
  hasLuckey: boolean;
  hasHostify: boolean;
  hasIcnea: boolean;
  hasOctorate: boolean;
  hasSuperhote: boolean;


  hasIgloohome: boolean;
  hasIglooCo: boolean;
  hasNuki: boolean;
  hasThekeys: boolean;
  hasMinut: boolean;
  hasKeynest: boolean;
  hasSwikly: boolean;

  stripePromotionCode: string;

}
