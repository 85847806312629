import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../core/services/admin.service";
import {YaagoAcademyShort} from "../../model/academy/YaagoAcademyShort";

@Component({
  selector: 'app-m10-yaago-academy',
  templateUrl: './m10-yaago-academy.component.html',
  styleUrls: ['./m10-yaago-academy.component.scss']
})
export class M10YaagoAcademyComponent implements OnInit {

  yaagoAcademyList: YaagoAcademyShort[] = [];
  currentYaagoAcademy: YaagoAcademyShort = new YaagoAcademyShort();
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.getYaagoAcademy().subscribe(data => {
      this.yaagoAcademyList = data;
    });
  }

  validateYaagoAcademyShort() {
   this.adminService.createOrUpdateYaagoAcademy(this.currentYaagoAcademy).subscribe(data => {
     this.currentYaagoAcademy = new YaagoAcademyShort();
     this.adminService.getYaagoAcademy().subscribe(data => {
       this.yaagoAcademyList = data;
     });
   });
  }

  createNew() {
    this.currentYaagoAcademy = new YaagoAcademyShort();
  }

}
