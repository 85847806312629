import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const googleTranslateApiURL = 'https://translation.googleapis.com/language/translate/v2';
const googleTranslateApiKey = window['googleApiKey']; // 'AIzaSyCS9qNVwqdSPoRv-aL-iMPCqayxeBIp-os';
const googleTranslateApiFormat = 'text';
export class GoogleTranslateService {
    constructor(http) {
        this.http = http;
    }
    translate(q, target, source) {
        return this.http.get(`${googleTranslateApiURL}?q=${q}&target=${target}&source=${source}&key=${googleTranslateApiKey}`, {});
    }
}
GoogleTranslateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleTranslateService_Factory() { return new GoogleTranslateService(i0.ɵɵinject(i1.HttpClient)); }, token: GoogleTranslateService, providedIn: "root" });
