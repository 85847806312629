import { HttpHeaders } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../interceptors/auth.interceptors";
import * as i3 from "./conf.service";
import * as i4 from "./http.service";
export class OnboardingService {
    constructor(http, authInterceptor, confService, httpService) {
        this.http = http;
        this.authInterceptor = authInterceptor;
        this.confService = confService;
        this.httpService = httpService;
    }
    getTokenHeaders() {
        return { headers: new HttpHeaders({ Authorization: this.authInterceptor.getAdminKey() }) };
    }
    onBoardingNormal(corporateId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/onboarding/normal/' + corporateId, this.getTokenHeaders());
    }
}
OnboardingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnboardingService_Factory() { return new OnboardingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthInterceptor), i0.ɵɵinject(i3.ConfService), i0.ɵɵinject(i4.HttpService)); }, token: OnboardingService, providedIn: "root" });
