<div style="width: 100vw; height: calc(100vh - 60px)">
  <app-leaflet-map
    [mapId]="'mapOfProperties'"
    [properties]="propertyPois"
    [showZoomControl]="true"
    [defaultZoom]="15"
    [showAllProperties]="true"
    [centerOfTheMap]="centerOfTheMap"
  ></app-leaflet-map>
</div>
