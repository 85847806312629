import { Component, OnInit } from '@angular/core';
import {PermissionBloc, PermissionItem, PlatformRole} from "../../model/PlatformRole";
import {ApiService} from "../../core/http/api.service";
import {cloneDeep} from 'lodash';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  roleToCreate =  new PlatformRole();

  loading = true;

  currentSelectedRole: PlatformRole;
  availableRoles: PlatformRole[] = [];
  activeRoles: PlatformRole[] = [];
  disabledRoles: PlatformRole[] = [];
  selectedPermissionBlocId: string;

  permissionBlocs: PermissionBloc[];
  filteredPermissionBloc: PermissionBloc[] = [];
  dispatchActions = false;


  constructor(private api: ApiService) {

  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.api.getAllRoles()
      .subscribe(data => {
        this.loading = false;
        this.availableRoles = data;
        this.initDisplayRole();
        if (this.activeRoles && this.activeRoles.length > 0) {
          this.currentSelectedRole = cloneDeep(this.activeRoles[0]);
        }
      });

    this.loadPermissionBlocs();
  }
  onChangeDisplayableName() {
    // clean string
    if (!this.roleToCreate || !this.roleToCreate.displayableName) {
      return;
    }
    this.roleToCreate.uniqueName = this.roleToCreate.displayableName.replace(/[^a-zA-Z0-9_]/g, '');
  }
  onChangeUniqueName() {
    // clean string
    if (!this.roleToCreate || !this.roleToCreate.uniqueName) {
      return;
    }

    setTimeout(() => {
      this.roleToCreate.uniqueName = this.roleToCreate.uniqueName.replace(/[^a-zA-Z0-9_]/g, '');
    }, 300);
  }

  addNewRole() {
    if (!this.roleToCreate || !this.roleToCreate.displayableName || ! this.roleToCreate.uniqueName) {
      alert('nom et identifiant sont obligatoires');
      return;
    }
    if (this.availableRoles) {
      const existing = this.availableRoles.find(item => item.uniqueName === this.roleToCreate.uniqueName);
      if (existing) {
        // this.snackbar.push(new SnackbarMessage('role-management.existing-unique-name', 'error'));
        alert('nom unique deja existant');
        return;
      }
    }

    this.api.createNewRole(this.roleToCreate)
      .subscribe(data => {
        if (!this.availableRoles) {
          this.availableRoles = [];
        }
        this.availableRoles.push(cloneDeep(data));
        this.currentSelectedRole = data;
        this.roleToCreate = new PlatformRole();
        this.activeRoles = this.availableRoles.filter(item => item.enabled);
        this.filterPermissionBlocs();
        // this.snackbar.push(new SnackbarMessage('role-management.role-created', 'info'));
        alert('créé avec succès');
      });
  }

  addPermissionBloc() {
    if (!this.selectedPermissionBlocId) {
      return;
    }
    const bloc = this.permissionBlocs.find(item => item.id === this.selectedPermissionBlocId);
    if (!this.currentSelectedRole.permissionBlocs) {
      this.currentSelectedRole.permissionBlocs = [];
    }
    this.currentSelectedRole.permissionBlocs.push(cloneDeep(bloc));
    this.filterPermissionBlocs();
  }

  selectRole(role: PlatformRole) {
    this.currentSelectedRole =  cloneDeep(role);
    this.filterPermissionBlocs();
  }

  loadPermissionBlocs() {
    this.api.getPermissions()
      .subscribe(
        data => {
          this.permissionBlocs = data;
          this.filterPermissionBlocs();
        }
      );
  }

  filterPermissionBlocs() {
    if (this.currentSelectedRole && this.currentSelectedRole.permissionBlocs) {
      const ids = this.currentSelectedRole.permissionBlocs.map(item => item.id);
      if (ids && ids.length > 0) {
        this.filteredPermissionBloc = this.permissionBlocs.filter(item => !ids.includes(item.id));
        return;
      }
    }
    this.filteredPermissionBloc = cloneDeep(this.permissionBlocs);
  }

  removeBloc(bloc: PermissionBloc, i: number) {
    this.currentSelectedRole.permissionBlocs.splice(i, 1);
    this.filterPermissionBlocs();
  }

  deleteRole() {
    /*this.roleEndpoints.deleteRole(this.currentCorporate?.id, this.currentSelectedRole)
      .subscribe(data => {
        const index = this.currentCorporate?.availableRoles?.findIndex(item => item.id === this.currentSelectedRole.id);
        if (index > -1) {
          this.currentCorporate.availableRoles?.splice(index, 1);
        }
        this.initDisplayRole();
        if (this.activeRoles && this.activeRoles.length > 0) {
          this.currentSelectedRole = cloneDeep(this.activeRoles[0]);
        }

        this.snackbar.push(new SnackbarMessage('role-management.role-deleted', 'info'));
      });*/
  }

  deactivateRole() {
    this.api.enableRole(this.currentSelectedRole, false, this.dispatchActions)
      .subscribe(data => {
        const index = this.availableRoles.findIndex(item => item.id === this.currentSelectedRole.id);
        if (index > -1) {
          this.availableRoles.splice(index, 1);
          this.availableRoles.push(cloneDeep(data));
        }
        this.initDisplayRole();
        if (this.activeRoles && this.activeRoles.length > 0) {
          this.currentSelectedRole = cloneDeep(this.activeRoles[0]);
        }

        alert('Role désactivé avec succès');
      });
  }

  reactivateRole(role: PlatformRole) {
    this.api.enableRole(role, true, false)
      .subscribe(data => {
        const index = this.availableRoles.findIndex(item => item.id === role.id);
        if (index > -1) {
          this.availableRoles.splice(index, 1);
          this.availableRoles.push(cloneDeep(data));
        }
        this.initDisplayRole();
        this.currentSelectedRole = data;

        alert('Role re-activé avec succès');
      });
  }

  duplicateRole() {
    this.api.duplicateRole(this.currentSelectedRole)
      .subscribe(data => {
        this.availableRoles.push(cloneDeep(data));
        this.currentSelectedRole = data;
        this.initDisplayRole();

        alert('Role dupliqué avec succès');
      });
  }

  saveRoleChanges() {
    this.api.updateRole(this.currentSelectedRole, this.dispatchActions)
      .subscribe(data => {
        this.currentSelectedRole = data;
        this.initDisplayRole();

        alert('Role sauvegardé');
      });
  }

  initDisplayRole() {
    this.activeRoles = this.availableRoles.filter(item => item.enabled);
    this.disabledRoles = this.availableRoles.filter(item => !item.enabled);
  }

  isParentDisabled(permItem: PermissionItem, bloc: PermissionBloc) {
    if (!permItem.linkedTo || permItem.linkedTo.length === 0) {
      return false;
    }

    let isParentDisabled = false;
    bloc.permissionList.forEach(item => {
      if (permItem.linkedTo.includes(item.name) && !item.enabled) {
        isParentDisabled = true;
      }
    });
    return isParentDisabled;
  }
}
