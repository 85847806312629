import { HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../interceptors/auth.interceptors";
import * as i3 from "./conf.service";
export class I18n_Service {
    constructor(http, authInterceptor, confService) {
        this.http = http;
        this.authInterceptor = authInterceptor;
        this.confService = confService;
    }
    getTokenHeaders() {
        return { headers: new HttpHeaders({ Authorization: this.authInterceptor.getAdminKey() }) };
    }
    formatErrors(error) {
        return throwError(error.error);
    }
    getI18n_Translation(i18n_TranslationSearch) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/translation/search', i18n_TranslationSearch, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    updateI18n_Translation(i18n_Translation) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/translation', i18n_Translation, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
}
I18n_Service.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function I18n_Service_Factory() { return new I18n_Service(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthInterceptor), i0.ɵɵinject(i3.ConfService)); }, token: I18n_Service, providedIn: "root" });
