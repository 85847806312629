import { Component, OnInit } from '@angular/core';
import {FunFactResults} from "../../model/FunFactResults";
import {FunFactService} from "../../core/services/funFactService";
import {AdminService} from "../../core/services/admin.service";
import {PaidUsers} from "../../model/PaidUsers";

@Component({
  selector: 'app-m3-home-stats',
  templateUrl: './m3-home-stats.component.html',
  styleUrls: ['./m3-home-stats.component.scss']
})
export class M3HomeStatsComponent implements OnInit {

  funFactResult: FunFactResults = new FunFactResults();
  paidUsers: PaidUsers = new PaidUsers();
  constructor(private funFactService: FunFactService,
              private adminService: AdminService) { }

  ngOnInit() {
    if (localStorage.getItem("lastRefreshDate") != null && localStorage.getItem("lastRefreshDate") != new Date().toDateString()) {
      localStorage.clear();
      localStorage.setItem("lastRefreshDate", new Date().toDateString());
    }
    this.refresh();
  }

  refresh() {
    const funFact = localStorage.getItem("funFactResult")
    if (funFact != null) {
      this.funFactResult = JSON.parse(funFact);
    } else {
      this.funFactResult = new FunFactResults();
      this.funFactService.getFunFact().subscribe(r => {
        this.funFactResult = r;
        localStorage.setItem('funFactResult', JSON.stringify(r));
      });
    }

    let paidUser = localStorage.getItem("paidUsers");
    if (paidUser != null) {
      this.paidUsers = JSON.parse(paidUser);
    } else  {
      this.paidUsers = new PaidUsers();
      this.adminService.getPaidUsers().subscribe(paid => {
        this.paidUsers = paid;
        localStorage.setItem('paidUsers', JSON.stringify(paid));
      });
    }
  }
  purge() {
    localStorage.removeItem('funFactResult');
    localStorage.removeItem('paidUsers');
  }

}
