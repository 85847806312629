import { Component, OnInit } from '@angular/core';
import {AuthInterceptor} from "../../core/interceptors/auth.interceptors";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  adminKey: string = '';
  constructor(private authInterceptor: AuthInterceptor) { }

  ngOnInit() {
    this.adminKey = this.authInterceptor.getAdminKey();
  }

}
