import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../core/services/admin.service";
import {PmsPropertyActive} from "../../model/pmsPropertyActive/PmsPropertyActive";
import {UtilService} from "../../core/services/UtilService";
import * as moment from "moment/moment";

@Component({
  selector: 'app-m11-yaago-pms-rev',
  templateUrl: './m11-yaago-pms-rev.component.html',
  styleUrls: ['./m11-yaago-pms-rev.component.scss']
})
export class M11YaagoPmsRevComponent implements OnInit {

  pmsPerf: PmsPropertyActive[] = [];
  months = [];
  currentMonth = moment(new Date()).format('YYYY-MM');
  constructor(private adminService: AdminService, private utilService: UtilService) { }

  ngOnInit() {

    // @ts-ignore
    this.months.push(moment(new Date()).format('YYYY-MM'));
    for (let i = 1; i < 12; i++) {
      // @ts-ignore
      this.months.push(moment(new Date()).subtract(i, 'months').format('YYYY-MM'));
    }
    this.adminService.getPmsPerf(this.currentMonth+ '-01').subscribe(data => {
      this.pmsPerf = data;
    });
  }
  getPmsPerf(month: string) {
    this.pmsPerf = [];
    this.currentMonth = month;
    this.adminService.getPmsPerf( this.currentMonth + '-01').subscribe(data => {
      this.pmsPerf = data;
    });
  }

}
