import { Component, OnInit } from '@angular/core';
import {Pagination} from "../../model/Pagination";
import {I18n_Service} from "../../core/services/I18n_Service";
import {I18n_Translation, I18n_TranslationSearch} from "../../model/I18n_Translation";

@Component({
  selector: 'app-m6-translation',
  templateUrl: './m6-translation.component.html',
  styleUrls: ['./m6-translation.component.scss']
})
export class M6TranslationComponent implements OnInit {

  textToSearch: string = "";
  showLoading = false;
  pagination = new Pagination();
  i18nTranslations: I18n_Translation[];
  i18n_TranslationSearch: I18n_TranslationSearch = new I18n_TranslationSearch();
  showSpanish = true;
  showEnglish = true;
  showFrench = true;
  showGerman = false;
  showItalian = false;
  showPortuguese = false;
  currentYaagoApp = "WEBAPP";

  constructor(private i18n_Service :I18n_Service) { }

  ngOnInit() {
    this.pagination.pageNumber = 0;
    this.pagination.pageSize = 20;
    this.refreshProcedure();
  }

  refreshProcedure() {
    this.i18n_TranslationSearch.pageNumber = this.pagination.pageNumber;
    this.i18n_TranslationSearch.size = this.pagination.pageSize;
    this.i18n_TranslationSearch.textToSearch = this.textToSearch;
    this.i18n_TranslationSearch.yaagoApp = this.currentYaagoApp;
    this.i18n_Service.getI18n_Translation(this.i18n_TranslationSearch).subscribe(res => {
      this.pagination.totalElements = res.totalElements;
      this.pagination.currentCount = res.content.length;
      this.pagination.totalPages = Math.ceil(res.totalElements / this.pagination.pageSize);
      if (this.pagination.totalPages == 0) {
        this.pagination.totalPages = 1;
      }
      this.showLoading = false;
      this.i18nTranslations = res.content;
      this.showLoading = false;
    });
  }

  filterByText() {

  }

  previousPage() {
    if (this.pagination.pageNumber>=1) {
      this.pagination.pageNumber--;
      this.pagination.last = (this.pagination.pageNumber == this.pagination.totalPages);
      this.pagination.first = (this.pagination.pageNumber == 0);
      this.refreshProcedure();
    }
  }
  nextPage() {
    if (this.pagination.pageNumber < this.pagination.totalPages) {
      this.pagination.pageNumber++;
      this.pagination.last = (this.pagination.pageNumber == this.pagination.totalPages);
      this.pagination.first = (this.pagination.pageNumber == 0);
      this.refreshProcedure();
    }
  }

  checkKeyPress($event) {
    if($event.keyCode === 13){
      this.pagination.pageNumber = 0;
      this.refreshProcedure();
    }
  }

  updateTranslation(i18nTranslation: I18n_Translation) {
    this.i18n_Service.updateI18n_Translation(i18nTranslation).subscribe(res => {
      console.log(res);
    });
  }
  show(type: string) {
    if ('english' === type) {
      this.showEnglish = ! this.showEnglish;
    }
    if ('french' === type) {
      this.showFrench = ! this.showFrench;
    }
    if ('german' === type) {
      this.showGerman = ! this.showGerman;
    }
    if ('spanish' === type) {
      this.showSpanish = ! this.showSpanish;
    }
    if ('italian' === type) {
      this.showItalian = ! this.showItalian;
    }
    if ('portuguese' === type) {
      this.showPortuguese = ! this.showPortuguese;
    }

  }

}
