/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../directives/debounce-click.directive";
import * as i9 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["appDebounceClick", ""], ["mat-flat-button", ""], ["tabindex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "debounceClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).clickEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("debounceClick" === en)) {
        var pd_2 = (_co.onClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, "-1"], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(4, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], disableRipple: [1, "disableRipple"] }, null), i1.ɵdid(5, 212992, null, 0, i8.DebounceClickDirective, [], { debounceTime: [0, "debounceTime"] }, { debounceClick: "debounceClick" }), (_l()(), i1.ɵted(6, 0, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.type; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.queryParams; var currVal_4 = _co.routerLink; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = _co.disabled; var currVal_6 = (_co.type !== _co.ButtonType.PRIMARY); _ck(_v, 4, 0, currVal_5, currVal_6); var currVal_7 = _co.debounceTime; _ck(_v, 5, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_8 = _co.text; _ck(_v, 6, 0, currVal_8); }); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i9.ButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonComponentNgFactory = i1.ɵccf("app-button", i9.ButtonComponent, View_ButtonComponent_Host_0, { debounceTime: "debounceTime", text: "text", disabled: "disabled", type: "type", routerLink: "routerLink", queryParams: "queryParams" }, { debounceClick: "debounceClick" }, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
