<div *ngIf="!openAddTags && !openEditTags && !openDeleteTags">


    <div class="card-container">
      <div class="card-view card-small" (click)="AddTag()" tabindex="0">
        <span>Add a Tags </span>
      </div>
    </div>

    <div class="card-container">
      <div class="card-view card-small" (click)="bulkMissingTranslations()" tabindex="0">
        <span>Bulk Missing Translations </span>
      </div>
    </div>

  <div class="loader" style="margin-top: 24px;" *ngIf="showLoading">
    <img  src="assets/loading.svg" alt="picto" class="margin-top-24 rotate-loader" >
  </div>

    <div class="card-container">
      <div class="card-input">
        <input
          id="search-input"
          class="search-input"
          [(ngModel)]="searchFilter"
          [placeholder]="'Search'"
          (ngModelChange)="filterByTag($event)"
        />
      </div>
    </div>

    <!--  <mat-tab-group>-->
    <!--    <mat-tab label="First"> Content 1 </mat-tab>-->
    <!--    <mat-tab label="Second"> Content 2 </mat-tab>-->
    <!--    <mat-tab label="Third"> Content 3 </mat-tab>-->
    <!--  </mat-tab-group>-->

    <div class="grid-container-category">
      <button class="button orangebutton click" (click)="getTags()">ALL</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('pubAndRestaurants')">PUB & RESTAURANTS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('spots')">SPOTS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('activities')">ACTIVITIES</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('cars')">CARS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('transports')">TRANSPORTS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('health')">HEALTH</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('essentials')">ESSENTIALS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('beauty')">BEAUTY</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('parcsAndOutdoors')">PARCS & OUTDOORS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('sports')">SPORTS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('shops')">SHOPS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('localProducers')">LOCAL PRODUCERS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('events')">EVENTS</button>
      <button class="button orangebutton click" (click)="filterTagByCategory('services')">SERVICES</button>
    </div>

  <table>
    <tr>
      <th *ngFor="let lang of langs" style="padding: 4px">{{lang.toUpperCase()}}</th>
      <th style="text-align: center;padding: 12px 8px;">Popular</th>
      <th style="text-align: center;padding: 12px 8px;"></th>
      <th style="text-align: center;padding: 12px 8px;"></th>
    </tr>
    <tr *ngFor="let tag of AllTag; let index = i">
      <td style="text-align: center;padding: 12px 8px;" *ngFor="let lang of langs">{{findRightTranslation(tag, lang)}}</td>
      <td style="text-align: center;padding: 12px 8px;">{{tag.popular ? 'YES' : ''}}</td>
      <td style="text-align: center;padding: 12px 8px;"><img class="img-tag" src="assets/button-cross-erase-saphir.svg" (click)="DeleteThisTag(tag)"/></td>
      <td  style="text-align: center;padding: 12px 8px;"><img class="img-tag" src="assets/icon-edit-recommendation.svg" (click)="EditTag(tag)"/></td>
    </tr>
  </table>
    <!--<div class="grid-super-container">
      <div class="grid-container-tags" style="font-weight: bold">
        <div style="display: flex; flex-direction: column">
          <div>DE</div>
        </div>
        <div style="display: flex; flex-direction: column">
          <div>EN</div>
        </div>
        <div style="display: flex; flex-direction: column">
          <div>ES</div>
        </div>
        <div style="display: flex; flex-direction: column">
          <div>FR</div>
        </div>
        <div style="display: flex; flex-direction: column">
          <div>IT</div>
        </div>
        <div style="display: flex; flex-direction: column">
          <div>Popular</div>
        </div>
        <div style="display: flex; flex-direction: column">
          <div></div>
        </div>
      </div>

      <div
        *ngFor="let tags of AllTag; let index = i"
        style="border-top: 1px solid #cecccc"
      >
        <div class="grid-container-tags">
          <div *ngFor="let translation of tags.tags; let index = i">
            <div style="display: flex; flex-direction: column">
              <div *ngIf="translation.language === 'en'">
                {{ translation.name }}
              </div>
              <div *ngIf="translation.language === 'fr'">
                {{ translation.name }}
              </div>
              <div *ngIf="translation.language === 'de'">
                {{ translation.name }}
              </div>
              <div *ngIf="translation.language === 'es' ">
                {{ translation.name }}
              </div>
              <div *ngIf="translation.language === 'it' ">
              {{ translation.name }}
              </div>



            </div>
          </div>
          <div style="display: flex; flex-direction: column">
            <div>{{tags.popular ? 'YES' : ''}}</div>
          </div>
          <div class="card-container-edit">
            <div (click)="EditTag(tags)" tabindex="0">
              <img
                class="img-tag"
                src="../assets/icon-edit-recommendation.svg"
                alt="edit"
              />
              <span style="margin-left: 5px">EDIT </span>
            </div>
          </div>

          <div class="card-container-delete">
            <div (click)="DeleteThisTag(tags)" tabindex="0">
              <img
                class="img-tag"
                src="../assets/button-cross-erase-saphir.svg"
                alt="delete"
              />
              <span style="margin-left: 5px">DELETE </span>
            </div>
          </div>
        </div>
      </div>
    </div>-->

  </div>


  <div *ngIf="openAddTags">
    <div class="card-container">
      <div class="card-view card-small" (click)="goBack()" tabindex="0">
        <span>Exit </span>
      </div>
    </div>

    <form id="form">
      <p>Add a new Tag</p>

      <div class="formulaire">

        <div class="dropdown">
          <p>CHOOSE THE CATEGORY</p>
          <label>
            <select
              [(ngModel)]="newTag.category"
              name="category"
              class="form-input"
            >
              <option value="pubAndRestaurants">Pub & Restaurants</option>
              <option value="spots">Spots</option>
              <option value="activities">Activities</option>
              <option value="cars">Cars</option>
              <option value="transports">Transports</option>
              <option value="health">Health</option>
              <option value="essentials">Essentials</option>
              <option value="beauty">Beauty</option>
              <option value="sports">sports</option>
              <option value="shops">shops</option>
              <option value="parcsAndOutdoors">Parcs & Outdoors</option>
              <option value="localProducers">Local Producers</option>
              <option value="events">Events</option>
              <option value="services">Services</option>



            </select>
          </label>
        </div>

        <input
          class="input-gurule"
          type="text"
          name="title"
          id="name"
          placeholder="nom du tag en français"
          style="height: 43px; width: 100%"
          [(ngModel)]="newTagTranslation.name"
        />
      </div>
    </form>

    <div class="card-container">
      <div class="card-view card-small" (click)="AddTags()" tabindex="0">
        <span>ADD TAG </span>
      </div>
    </div>
    <div class="card-container">
      <div class="card-view card-small" (click)="cancel()" tabindex="0">
        <span>CANCEL </span>
      </div>
    </div>

  </div>

  <div *ngIf="openEditTags">

    <div class="edit-gurules" style="margin-top: 50px">
      <form style="display: flex;flex-flow: wrap;">
        <div class="formulaire" *ngFor="let information of currentTag.tags; let index = i">
          <div><p>{{information.language}}</p></div>
          <input  class="input-gurule" [(ngModel)]="information.name" [ngModelOptions]="{standalone: true}">
        </div>

        <div style="margin-top: 12px">
          <input type="checkbox" [(ngModel)]="currentTag.popular" [ngModelOptions]="{standalone: true}"/>
          Popular
        </div>
      </form>




    </div>

    <div class="card-container">
      <div
        class="card-view card-small"
        (click)="UpdateTags(currentTag)"
        tabindex="0"
      >
        <span>update </span>
      </div>
    </div>
    <div class="card-container">
      <div class="card-view card-small" (click)="cancel()" tabindex="0">
        <span>CANCEL </span>
      </div>
    </div>
  </div>

  <div *ngIf="openDeleteTags">
    <div class="card-container" *ngIf="currentTag">
      <div class="delete">
        <p>Are you sure you want to delete this Tags : {{ currentTag.tags[1].name }}?</p>
      </div>

      <div class="card-container">
        <div
          class="card-del card-small"
          (click)="DeleteTag(currentTag)"
          tabindex="0"
        >
          <span>DELETE </span>
        </div>
      </div>

      <div class="card-container">
        <div class="card-view card-small" (click)="cancel()" tabindex="0">
          <span>CANCEL </span>
        </div>
      </div>
    </div>
  </div>


