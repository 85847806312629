<button mat-flat-button appDebounceClick [debounceTime]="debounceTime"
        tabindex="-1"
        [disableRipple]="type !== ButtonType.PRIMARY"
        [ngClass]="type"
        [routerLink]="routerLink"
        [queryParams]="queryParams"
        [disabled]="disabled"
        (debounceClick)="onClick()">
  {{text}}
</button>
