import { Component, OnInit } from '@angular/core';
import {FunFactService} from "../../core/services/funFactService";
import {YaagoPerformance} from "../../model/YaagoPerformance";
import {MrrResponse} from "../../model/MrrResponse";
import {UtilService} from "../../core/services/UtilService";

@Component({
  selector: 'app-m4-yaago-perf',
  templateUrl: './m4-yaago-perf.component.html',
  styleUrls: ['./m4-yaago-perf.component.scss']
})
export class M4YaagoPerfComponent implements OnInit {

  yaagoPerformances: YaagoPerformance[] = [];
  mrrReponse: MrrResponse;
  constructor(private funfactService: FunFactService, public utilService: UtilService) { }

  ngOnInit() {
    this.funfactService.getFunFactMonthly().subscribe( res => {
      this.yaagoPerformances = res;
    });
    this.funfactService.getMrrCustomer().subscribe( res => {
      this.mrrReponse = res;
    });
  }

  getClass(index: string) : string {
    if ( index && index.startsWith("-") ){
      return "text-danger";
    } else {
      return "text-success";
    }
  }
  calcActiveUSerPercentage(index: number) : string {
    if (index > 0 && this.yaagoPerformances[index - 1].numberOfActiveUsers > 0) {
      return Math.round((this.yaagoPerformances[index].numberOfActiveUsers - this.yaagoPerformances[index - 1].numberOfActiveUsers) / this.yaagoPerformances[index - 1].numberOfActiveUsers * 100) + " %";
    } else {
      return "";
    }
  }
  calcActiveUSerYaagoTeamPercentage(index: number) : string {
    if (index > 0 && this.yaagoPerformances[index - 1].numberOfActiveYaagoTeamUsers > 0) {
      return Math.round((this.yaagoPerformances[index].numberOfActiveYaagoTeamUsers - this.yaagoPerformances[index - 1].numberOfActiveYaagoTeamUsers) / this.yaagoPerformances[index - 1].numberOfActiveYaagoTeamUsers * 100) + " %";
    } else {
      return "";
    }
  }
  calcPropertyPercentage(index: number) : string {
    if (index > 0) {
      return Math.round((this.yaagoPerformances[index].numberOfPaidProperties - this.yaagoPerformances[index - 1].numberOfPaidProperties) / this.yaagoPerformances[index - 1].numberOfPaidProperties * 100) + " %";
    } else {
      return "";
    }
  }
  calcPerfPercentage(index: number) : string {
    if (index > 0) {
      return Math.round((this.yaagoPerformances[index].mrr - this.yaagoPerformances[index - 1].mrr) / this.yaagoPerformances[index - 1].mrr * 100) + " %";
    } else {
      return "";
    }
  }
  calculateTotalCustomerPartner() : number {
    return this.mrrReponse.partnerRetroCommissions.map( p => p.nbCustomer).reduce((a, b) => a + b, 0);

  }
  calculateTotalCustomerSegment() : number {
    return this.mrrReponse.customerSegmentation.map( p => p.totalCustomers).reduce((a, b) => a + b, 0);
  }
  calculateTotalPropertiesSegment() : number {
    return this.mrrReponse.customerSegmentation.map( p => p.totalProperties).reduce((a, b) => a + b, 0);
  }
  calculateTotalMrrSegment() : number {
    return this.mrrReponse.customerSegmentation.map( p => p.mrrHT).reduce((a, b) => a + b, 0);
  }
  calculateTotalPartner() : number {
    return this.mrrReponse.partnerRetroCommissions.map( p => p.commissionsHT).reduce((a, b) => a + b, 0);
  }

}
