import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PersonFilter} from "../../../model/PersonFilter";

@Component({
  selector: 'app-m01-users-filters',
  templateUrl: './m01-users-filters.component.html',
  styleUrls: ['./m01-users-filters.component.scss']
})
export class M01UsersFiltersComponent implements OnInit {

  filterPerson = new PersonFilter()
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<M01UsersFiltersComponent>) { }

  ngOnInit() {
    if (this.data && this.data.filterPerson) {
      this.filterPerson = this.data.filterPerson;
      console.log(this.filterPerson);
    }
  }

  save() {
    console.log(this.filterPerson);
      this.dialogRef.close(this.filterPerson);
  }

}
