import {Injectable, OnInit} from '@angular/core';
import { HttpHeaders , HttpParams} from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
    providedIn : 'root'
})

export class HttpService {

    //adminKey: string;

    constructor(
    ){ }


    public setAdminKey(key: string) {
      localStorage.setItem('adminKey', key);
    }
    public getAdminKey(): string {
      let adminKey = localStorage.getItem('adminKey');
      return adminKey == null ? '' : adminKey;
    }


    static getHeaders(): { headers: HttpHeaders } {
        return {headers: new HttpHeaders({'Content-Type': 'application/json'})};
      }

      static getOptionsWithParams(httpParams: HttpParams) {
        return {headers: new HttpHeaders({'Content-Type': 'application/json'}), params: httpParams};
      }

      static getHeadersForUpload(): { headers: HttpHeaders } {
        return {headers: new HttpHeaders()};
      }

      public getTokenHeaders(): { headers: HttpHeaders } {
        return {headers: new HttpHeaders({Authorization: this.getAdminKey()} )};
      }

      public formatErrors(error: any) {
        return throwError(error);
      }
}
