import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ConfService} from "./conf.service";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";
import {AuthInterceptor} from "../interceptors/auth.interceptors";

@Injectable({ providedIn: 'root'})
export class OnboardingService {
  constructor(private http: HttpClient,
              private authInterceptor: AuthInterceptor,
              private confService: ConfService,
              private httpService: HttpService
  ) {}

  public getTokenHeaders(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders({Authorization: this.authInterceptor.getAdminKey()}, )};
  }

  onBoardingNormal(corporateId: string): Observable<void> {
    return this.http.get<void>(
      this.confService.getBackendApiEndPoint() + 'api-v2/admin/onboarding/normal/' + corporateId, this.getTokenHeaders());
  }

}
