import { Component, OnInit } from '@angular/core';
import {FunFactService} from "../../core/services/funFactService";
import {AirbnbAccount} from "../../model/AirbnbAccount";
import {UtilService} from "../../core/services/UtilService";
import {BookingDotComAccount} from "../../model/BookingDotComAccount";

@Component({
  selector: 'app-m5-ota-accounts',
  templateUrl: './m5-ota-accounts.component.html',
  styleUrls: ['./m5-ota-accounts.component.scss']
})
export class M5OtaAccountsComponent implements OnInit {

  airbnbAccount: AirbnbAccount[] = [];
  bookingDotComAccount: BookingDotComAccount[] = [];
  constructor(private funfactService: FunFactService,
              public utilService: UtilService) { }

  ngOnInit() {
    this.funfactService.getAirbnbOtaAccount().subscribe( res => {

      this.airbnbAccount = res;
      this.airbnbAccount = this.airbnbAccount.filter( a => a.lastSyncElapseTimeForProperty != null );
      this.airbnbAccount.sort( (a, b) => b.numberOfProperties - a.numberOfProperties);
    });

    this.funfactService.getBookingDotComAccount().subscribe( res => {

      this.bookingDotComAccount = res;
      this.bookingDotComAccount = this.bookingDotComAccount.filter( a => a.numberOfProperties > 0);
      this.bookingDotComAccount.sort( (a, b) => b.numberOfProperties - a.numberOfProperties);
    });

  }

}
