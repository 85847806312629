import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthInterceptor} from "../interceptors/auth.interceptors";
import {ConfService} from "./conf.service";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {I18n_Translation, I18n_TranslationDto, I18n_TranslationSearch} from "../../model/I18n_Translation";

@Injectable({  providedIn: 'root' })
export class I18n_Service {
  constructor(private http: HttpClient,
              private authInterceptor: AuthInterceptor,
              private confService: ConfService) {
  }

  public getTokenHeaders(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders({Authorization: this.authInterceptor.getAdminKey()}, )};
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  public getI18n_Translation(i18n_TranslationSearch: I18n_TranslationSearch): Observable<I18n_TranslationDto> {
    return this.http.post<I18n_TranslationDto>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/translation/search', i18n_TranslationSearch, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }
  public updateI18n_Translation(i18n_Translation: I18n_Translation): Observable<I18n_Translation> {
    return this.http.put<I18n_Translation>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/translation', i18n_Translation, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }
}
