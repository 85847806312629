import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GoogleTranslateService } from "src/app/core/services/google.translation.service";
import { TagsService } from "src/app/core/services/tags.service";
import { Tags, Translations } from "src/app/model/tags";
import { cloneDeep } from 'lodash';


@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  viewTags = false;
  openAddTags = false;
  newTag: Tags = new Tags();
  searchFilter = '';
  FilteredTags: Tags[];
  AllArrayTags: Tags[];
  AllTag: Tags[];
  private _onDestroy = new Subject();
  tags: Translations[] = [];
  viewTagCategory: Tags[] = [];
  openEditTags = false;
  openDeleteTags = false;
  currentTag: Tags;
  newTagTranslation: Translations = new Translations();
  langs = ["fr", "en", "de", "es", "it", "da", "ja", "nl", "no", "pl", "pt", "ru", "sv", "tr", "zh"];
  ngOnInit(){
    this.newTagTranslation.language = 'fr';
    this.getTags();
  }

  constructor(
    private tagsService: TagsService,
    private router : Router,
    private googleService: GoogleTranslateService,
  ){}

  goBack(){
    this.tagsService.goBackSub.next(true);
    // this.router.navigate(['dashboard'])
    this.viewTags = true;
    this.openAddTags = false;
  }

  AddTag() {
    this.viewTags = false;
    this.openAddTags = true;
    this.newTag = new Tags();

  }
  AddTags() {

    if (this.newTag.category && this.newTagTranslation.name && this.newTagTranslation.language) {
      this.newTag.tags = [];
      this.newTag.tags.push(this.newTagTranslation);
      this.tagsService.addTags(this.newTag).subscribe(t => {
        /*this.ArrayTags = [];
        this.ArrayTags.push(this.newTagTranslation);
        t.tags = this.ArrayTags;
        this.UpdateTags(t);
         this.Translate(t);*/
        const form = document.getElementById('form');
        // @ts-ignore
         form.reset();
         this.openAddTags =true;
         this.openEditTags = false;
         this.openDeleteTags = false;
         this.getTags();
      });
    } else {
      alert('Choose category or language');
    }
  }
  UpdateTags(tag: Tags) {
    this.tagsService.updateTags(tag).subscribe(g => {
      this.getTags();
      // this.goBack();
      this.openEditTags = false;
    });
  }
  filterByTag(text) {
    if (text.length > 0) {
      this.FilteredTags = [];
      this.AllArrayTags.filter(t => t.tags.filter(u => {
         if ( u.name.toLowerCase().includes(text.toLowerCase()) ) {
               const countId = this.FilteredTags.filter(x => x.id === t.id).length;
               if (countId <= 0) {
                 this.FilteredTags.push(t);
               }
         }
      }));
      this.AllTag = this.FilteredTags;
    } else {
      this.getTags();
    }
  }

  Translate(tag: Tags) {
    this.currentTag = tag;
    const ArrayLanguage = ['en', 'it', 'de' , 'es', 'fr'];
    const arrayLength = ArrayLanguage.length;

    for (let i = 0; i < arrayLength; i++) {
      let translate = null;
      this.currentTag.tags.forEach(tr => {
        if (tr.language === ArrayLanguage[i]) {
          translate = null;
        }
      });

      if (translate === null) {
        this.googleService.translate(this.currentTag.tags[0].name, ArrayLanguage[i], '').subscribe(t => {
          const TagTranslation = new Translations();
          TagTranslation.language = ArrayLanguage[i];
          // @ts-ignore
          TagTranslation.name = t.data.translations[0].translatedText;
          this.currentTag.tags.push(TagTranslation);
          if (this.currentTag.tags[0].name === null) {
            this.currentTag.tags.splice(0, 1);
          }
          this.UpdateTags(this.currentTag);
        });
      }
    }
  }
  getTags() {
    this.viewTags = true;
    this.openAddTags = false;
    this.tagsService.getTags().pipe(takeUntil(this._onDestroy)).subscribe(Alltags => {

      this.AllTag = Alltags;
      const arrayLength = this.AllTag.length;
      for (let i = 0; i < arrayLength; i++) {
        this.tags = (Alltags[i].tags.sort((a, b) => a.language > b.language ? 1 : -1));
      }
      this.AllArrayTags = cloneDeep(Alltags);
    });
    // this.getAllTags();

  }


  filterTagByCategory(type) {
    this.viewTagCategory = this.AllArrayTags.filter(u => u.category === type);
    this.AllTag = this.viewTagCategory;
  }


  // EditTag(tag: Tags) {
  //   this.viewTags = false;
  //   this.openAddTags = false;
  //   this.openEditTags = true;
  //   this.openDeleteTags = false;
  //   this.currentTag = tag;
  // }
  showLoading = false;
  EditTag(tag: Tags) {
    this.viewTags = false;
    this.openAddTags = false;
    this.openEditTags = true;
    this.openDeleteTags = false;
    this.currentTag = tag;
    const languages=['de','en','es','fr','it']
    let i=0;
    let j=0;
    let exist =false;
    while( i<languages.length){
      exist=false;
      j=0;
      while(!exist && j<this.currentTag.tags.length){
        if(languages[i]==<any>this.currentTag.tags[j].language){
          exist=true;
        }
        j++;
      }
      if(!exist){
        this.currentTag.tags[j]={language:languages[i],name:""};
      }
      i++;
    }

  }

  DeleteThisTag(tag: Tags) {
    this.openDeleteTags = true;
    this.viewTags = false;
    this.currentTag = tag;
  }


  DeleteTag(tag: Tags) {
    this.tagsService.deleteTags(tag.id)
    .subscribe(d => {
      alert('this Tags has been deleted');
      this.getTags();

      this.openDeleteTags = false;
    });

  }


  cancel(){

    this.openAddTags = false;
    this.openEditTags = false;
    this.openDeleteTags = false;
  }

  findRightTranslation(tag: Tags, lang: string) {
    if (!tag || !tag.tags) {
      return 'N/A';
    }
    const trans = tag.tags.find(item => item.language === lang);
    if (!trans) {
      return 'N/A';
    }
    return  trans.name;
  }

  bulkMissingTranslations() {
    this.showLoading = true;
    this.tagsService.bulkMissingTranslations()
      .subscribe(data => {
        this.showLoading = false;
        this.getTags();
      }, error => {
        alert('Error Occured when trying to translate tags');
      });
  }
}
