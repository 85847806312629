import { Injectable } from '@angular/core';
import {ApiService} from '../http/api.service';
import {Observable, Subject} from 'rxjs';
import {Tags} from '../../model/tags';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  goBackSub:Subject<boolean>= new Subject<boolean>();

  constructor(private api: ApiService) {


  }

  getTags(): Observable<Tags[]> {
    return this.api.getTags();
  }

  addTags( tags: Tags): Observable<Tags> {
    return this.api.addTags( tags);
  }

  updateTags(tagsId: Tags): Observable<Tags> {
    return this.api.updateTags(tagsId);
  }

  deleteTags(tagsId: string): Observable<void> {
    return this.api.deleteTags(tagsId);
  }

  bulkMissingTranslations(): Observable<void> {
    return this.api.bulkMissingTranslations( );
  }

}
