import {AfterViewInit, Component,  Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import {Router} from '@angular/router';
import {Poi} from "../../model/poi";
import {Property} from "../../model/property";
import {Corporate} from "../../model/Corporate";
import {Person} from "../../model/person";
import {PropertyPoi} from "../../model/PropertyPoi";

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements OnInit, AfterViewInit, OnChanges {

  showCorporate = false;
  currentProperty : any;

  @Input() me: Person;
  @Input() centerOfTheMap: Poi;
  @Input() propertyPoi: Poi;
  @Input() whereIamPoi: Poi;
  @Input() mapId = 'map';

  @Input() properties: PropertyPoi[] = [];
  @Input() property: Property;
  @Input() corporate: Corporate;

  @Input() showAllProperties      = false;
  @Input() showProperty           = false;
  @Input() showOwner              = true;

  @Input() showZoomControl        = true;
  @Input() maxDistance            = 50;
  @Input() language               = 'fr';
  @Input() showChecks             = false;
  @Input() recapVersion           = 'v2';
  @Input() defaultZoom            = 14;

  private map;
  initializationMapDone = false;
  markerCluster: any;
  markers: any[] = [];




  public iconMarkers: any;

  index = 0;
  tags = [];

  transports: any;
  cars:  any;
  activities:  any;
  spots:  any;
  pubAndRestaurants:  any;
  health:  any;
  essentials:  any;
  beauty:  any;
  sports:  any;
  shops:  any;
  parcsAndOutdoors: any;
  events: any;
  localProducers:  any;
  tesla: any;
  charger: any;


  yaago_parcsAndOutdoors : any;
  yaago_shops : any;
  yaago_sports : any;
  yaago_beauty : any;
  yaago_essentials : any;
  yaago_health : any;
  yaago_pubAndRestaurants : any;
  yaago_spots : any;
  yaago_activities : any;
  yaago_cars : any;
  yaago_transports : any;
  yaago_events : any;
  yaago_localProducers : any;
  yaago_tesla: any;
  yaago_charger: any;

  insta_parcsAndOutdoors : any;
  insta_shops: any;
  insta_sports : any;
  insta_beauty : any;
  insta_essentials : any;
  insta_health : any;
  insta_pubAndRestaurants : any;
  insta_spots : any;
  insta_activities : any;
  insta_cars : any;
  insta_transports : any;
  insta_events : any;
  insta_localProducers: any;

  constructor(public router: Router) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.properties) {
      this.initProperties();
    }

  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.initMap();
      if (this.properties || this.propertyPoi) {
        this.initProperties();
      }
      if (this.centerOfTheMap) {
        if (this.initializationMapDone) {
          if (this.map != null) {
            this.map.panTo([this.centerOfTheMap.y, this.centerOfTheMap.x]);
            this.map.setZoom(this.defaultZoom);
          }
        }

      }
    }, 500);
  }

  initMap() {

    try {
      this.markerCluster.clearLayers();
    } catch(err) {

    }

    if (!this.initializationMapDone && this.centerOfTheMap) {


      this.map = L.map(this.mapId + '', {
        center: [this.centerOfTheMap.y, this.centerOfTheMap.x],
        zoom: this.defaultZoom,
        zoomControl: false,

      });

      this.map.on('click', (e) => {
        this.showCorporate= false;
      });


      if (this.showZoomControl) {
        this.map.addControl(L.control.zoom({position: 'bottomright'}));
      }

      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 22,
        minZoom: 3,

        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">Yaago</a>'
      });

      this.map.on('moveend', ($event) => {
        this.centerOfTheMap.x = this.map.getCenter().lng;
        this.centerOfTheMap.y = this.map.getCenter().lat;
      });

      tiles.addTo(this.map);

      // this.markerGroup = L.layerGroup().addTo(this.map);

      this.markerCluster = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        removeOutsideVisibleBounds: true,
        disableClusteringAtZoom: 14
      });

      this.map.addLayer(this.markerCluster);
      this.initializationMapDone = true;
    }
  }
  initProperties() {

  if (this.map) {

    if (this.properties != null) {

      let iconUrl: string | null = null;

      this.properties.forEach(p => {
        if (p.poi != null) {
          if (p.corporateFaviconUrl != null) {
            iconUrl = p.corporateFaviconUrl;
          } else if (p.corporateLogo != null) {
            iconUrl = p.corporateLogo;
          } else{
            iconUrl = './assets/icons/marker-property.svg';
          }
          const propertyIcon = L.icon({
            iconUrl,
            iconSize: [40, 40],
            iconAnchor: [17, 34]
          });
          let newMarker = new L.marker([p.poi.y, p.poi.x], {icon: propertyIcon, propertyName: p.title, corporateName: p.corporateName, address: p.fullAddress})
            .on('click', (e) => {
              this.showCorporate = true;
              this.currentProperty = { corporate: e.target.options.corporateName,
                propertyAddress : e.target.options.address,
                propertyTitle: e.target.options.propertyName} ;

              //this.recommendationSelected.emit(e.target.options.reco);
            })
            .addTo(this.map);
        } else {
          console.log("undefined poi found on property :" + p.title + " | " + p.corporateName);
        }
      });
    }


  }

  }

  initRecommendations() {

    try {
      this.markerCluster.clearLayers();
    } catch(err) {

    }
    this.markers = [];
    // console.log("recommendations Markers", this.recommendationList);






  }




  private getPhotoIndex(photos: string[], photoUrl: string) {
    if (photos && photoUrl) {
      const fPhoto = photoUrl.replace('?aki_policy=large', '?aki_policy=medium');
      return photos.findIndex(p => p.startsWith(fPhoto));
    } else {
      return -1;
    }
  }



  initRecommendationMarkers() {
    const iconBaseRecommendation = './assets/icons/recommendation/';
    const iconBaseRecommendationInstagram = './assets/icons/recommendation/instagram/';


    this.parcsAndOutdoors = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-parcsAndOutdoors.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. shops = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-shops.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. sports = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-sports.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. beauty = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-beauty.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. essentials = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-essentials.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. health = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-health.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. pubAndRestaurants = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-pubAndRestaurants.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. spots = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-spots.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. activities = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-activities.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. cars = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-cars.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. transports = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-transports.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. events = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-events.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. tesla = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-tesla.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. charger = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-charger.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. localProducers = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-localProducers.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });

    this.yaago_parcsAndOutdoors =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-5-parc.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_shops =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-8-magasin.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_sports =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-2-sports.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_beauty =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-10-beaute.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_essentials =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-7-essentiel.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_health =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-4-sante.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_pubAndRestaurants =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-11-restaurant.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_spots =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-3-monument.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_activities =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-6-activite.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_cars =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-9-voiture.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_transports =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-1-transport.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });

    this.yaago_events =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-12-events.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_tesla =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-14-tesla.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_charger =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-15_charger.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });

    this.yaago_localProducers =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-13-localProducers.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });



    this.insta_parcsAndOutdoors =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-5-parc.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_shops =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-8-magasin.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_sports =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-2-sports.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_beauty =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-10-beaute.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_essentials =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-7-essentiel.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_health =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-4-sante.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_pubAndRestaurants =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-11-restaurant.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_spots =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-3-monument.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_activities =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-6-activite.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_cars =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-9-voiture.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_transports =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-1-transport.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_events = L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-12-events.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_localProducers =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-13-localProducers.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });

    this.iconMarkers = {
      transports: { icon: this.transports },
      cars: { icon: this.cars },
      activities: { icon: this.activities },
      spots: { icon: this.spots },
      pubAndRestaurants: { icon: this.pubAndRestaurants },
      health: { icon: this.health },
      essentials: { icon: this.essentials },
      beauty: { icon: this.beauty },
      sports: { icon: this.sports },
      shops: { icon: this.shops },
      parcsAndOutdoors: { icon: this.parcsAndOutdoors },
      events: { icon: this.events },
      localProducers: { icon: this.localProducers },
      tesla: { icon: this.tesla },
      charger: { icon: this.charger },

      yaago_transports: { icon: this.yaago_transports },
      yaago_cars: { icon: this.yaago_cars },
      yaago_activities: { icon: this.yaago_activities },
      yaago_spots: { icon: this.yaago_spots },
      yaago_pubAndRestaurants: { icon: this.yaago_pubAndRestaurants },
      yaago_health: { icon: this.yaago_health },
      yaago_essentials: { icon: this.yaago_essentials },
      yaago_beauty: { icon: this.yaago_beauty },
      yaago_sports: { icon: this.yaago_sports },
      yaago_shops: { icon: this.yaago_shops },
      yaago_parcsAndOutdoors: { icon: this.yaago_parcsAndOutdoors },
      yaago_events: { icon: this.yaago_events },
      yaago_localProducers: { icon: this.yaago_localProducers },
      yaago_tesla: { icon: this.yaago_tesla },
      yaago_charger: { icon: this.yaago_charger },

      insta_transports: { icon: this.insta_transports },
      insta_cars: { icon: this.insta_cars },
      insta_activities: { icon: this.insta_activities },
      insta_spots: { icon: this.insta_spots },
      insta_pubAndRestaurants: { icon: this.insta_pubAndRestaurants },
      insta_health: { icon: this.insta_health },
      insta_essentials: { icon: this.insta_essentials },
      insta_beauty: { icon: this.insta_beauty },
      insta_sports: { icon: this.insta_sports },
      insta_shops: { icon: this.insta_shops },
      insta_parcsAndOutdoors: { icon: this.insta_parcsAndOutdoors },
      insta_events: { icon: this.insta_events },
      insta_localProducers: { icon: this.insta_localProducers },

    };

  }






}
