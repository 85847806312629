<div class="y3-margin-top-24 y3-padding-left-16 y3-padding-right-16">
  <div class="flex-row">
    <div class="m9-card" [ngStyle]="{
    'background-color' : (dashboardTechResponse.countPendingGuestyWebhook > 20) ? 'red' : 'white',
    'color': (dashboardTechResponse.countPendingGuestyWebhook > 20)? 'white': 'black' }">
      <div><strong>GUESTY BUFFER</strong></div>
      <div class="m9-card-counter"

      >{{dashboardTechResponse.countPendingGuestyWebhook}}</div>
    </div>

    <div class="m9-card"
         [ngStyle]="{
    'background-color' : (dashboardTechResponse.countPendingBookingsyncWebhook > 20) ? 'red' : 'white',
    'color': (dashboardTechResponse.countPendingBookingsyncWebhook > 20)? 'white': 'black' }">
      <div><strong>SMILY BUFFER</strong></div>
      <div class="m9-card-counter"
      >{{dashboardTechResponse.countPendingBookingsyncWebhook}}</div>
    </div>

    <div class="m9-card"
         [ngStyle]="{
    'background-color' : (dashboardTechResponse.countPendingHospitableWebhook > 20) ? 'red' : 'white',
    'color': (dashboardTechResponse.countPendingHospitableWebhook > 20)? 'white': 'black' }">
      <div><strong>HOSPITABLE BUFFER</strong></div>
      <div class="m9-card-counter"
      >{{dashboardTechResponse.countPendingHospitableWebhook}}</div>
    </div>

  </div>
</div>
