import * as moment from "moment/moment";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class UtilService {
    constructor(translateService) {
        this.translateService = translateService;
    }
    formatDateOnly(date) {
        // TO DO NICE TO HAVE FORMAT US format
        // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
        let ret = '';
        if (date != null && date !== '') {
            moment.locale(this.translateService.currentLang);
            ret = moment(date).format('DD/MM/YYYY');
        }
        return ret;
    }
    formatDateTime(date) {
        // TO DO NICE TO HAVE FORMAT US format
        // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
        let ret = '';
        if (date != null && date !== '') {
            ret = moment(date).format('DD/MM/YYYY - HH:mm');
        }
        return ret;
    }
    difDays(date) {
        let ret = 0;
        if (date != null && date !== '') {
            ret = moment(new Date()).diff(moment(date), 'days');
        }
        return Math.abs(ret);
    }
    noAbsdifDays(date) {
        let ret = 0;
        if (date != null && date !== '') {
            ret = moment(new Date()).diff(moment(date), 'days');
        }
        return ret;
    }
    difMinutes(date) {
        let ret = 0;
        if (date != null && date !== '') {
            ret = moment(new Date()).diff(moment(date), 'minutes');
        }
        return Math.abs(ret);
    }
    getFirstLetterString(firstName, lastName, email) {
        if (firstName) {
            return firstName.substr(0, 1);
        }
        if (lastName) {
            return lastName.substr(0, 1);
        }
        if (email) {
            return email.substr(0, 1);
        }
        else {
            return "U";
        }
    }
}
UtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilService_Factory() { return new UtilService(i0.ɵɵinject(i1.TranslateService)); }, token: UtilService, providedIn: "root" });
