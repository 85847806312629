import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../http/api.service";
export class TagsService {
    constructor(api) {
        this.api = api;
        this.goBackSub = new Subject();
    }
    getTags() {
        return this.api.getTags();
    }
    addTags(tags) {
        return this.api.addTags(tags);
    }
    updateTags(tagsId) {
        return this.api.updateTags(tagsId);
    }
    deleteTags(tagsId) {
        return this.api.deleteTags(tagsId);
    }
    bulkMissingTranslations() {
        return this.api.bulkMissingTranslations();
    }
}
TagsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagsService_Factory() { return new TagsService(i0.ɵɵinject(i1.ApiService)); }, token: TagsService, providedIn: "root" });
