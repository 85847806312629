import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../core/services/admin.service";
import {DashboardTechResponse} from "../../model/dashboard/DashboardTechResponse";
import {isWhitespace} from "codelyzer/angular/styles/chars";

@Component({
  selector: 'app-m9-dashboard-tech',
  templateUrl: './m9-dashboard-tech.component.html',
  styleUrls: ['./m9-dashboard-tech.component.scss']
})
export class M9DashboardTechComponent implements OnInit {

  dashboardTechResponse: DashboardTechResponse;
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.getDashboard().subscribe(data => {
      this.dashboardTechResponse = data;
    });
  }

  protected readonly isWhitespace = isWhitespace;
}
