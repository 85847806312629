import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import { ConfService } from './conf.service';
import { catchError } from 'rxjs/operators';
import { HttpService } from './http.service';
import { PersonDto } from '../../model/PersonDto';
import {PersonFilter} from "../../model/PersonFilter";
import {TrialPeriodUpdate} from "../../model/TrialPeriodUpdate";
import {MinimumPropertiesUpdate} from "../../model/MinimumPropertiesUpdate";
import {Person} from "../../model/person";
import {PromotionCodeUpdate} from "../../model/PromotionCodeUpdate";
import {IndividualCompanyUpdate} from "../../model/IndividualCompanyUpdate";
import {Corporate} from "../../model/Corporate";
import {StripeTaxRate} from "../../model/StripeTaxRate";
import {CorporateAndTaxRate} from "../../model/CorporateAndTaxRate";
import {YaagoAirbnbCorporateOwnerError} from "../../model/YaagoAirbnbCorporateOwnerError";
import {AirbnbAccount} from "../../model/AdminCorporateDetailsDto";



@Injectable({
  providedIn: 'root'
})

export class UsersService {

    constructor(private http: HttpClient,
                private confService: ConfService,
                private httpService: HttpService
    ) {}

    public personSearch(person: PersonFilter): Observable<PersonDto> {
      return this.http.post<PersonDto>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/dashboard/person/search-admin' , person , this.httpService.getTokenHeaders())
        .pipe(catchError(this.httpService.formatErrors));
    }

    public corporateSearch(corporateId: string): Observable<any> {
      return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/corporate/' +  corporateId , this.httpService.getTokenHeaders())
        .pipe(catchError(this.httpService.formatErrors));
    }


  public forceAirbnbAccount(airbnbAccountEmail: string, corporateOwnerEmail: string): Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/airbnb/synchroAccount?airbnbEmail=' + airbnbAccountEmail + "&personEmail=" + corporateOwnerEmail , this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public setAirbnbInvalid(otaAccountId: string): Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/ota-account/set-invalid/' + otaAccountId, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public reconnectAirbnbAccount(airbnbAccountEmail: string): Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/airbnb/202404AirbnbLogin?airbnbEmail=' + airbnbAccountEmail, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceBeds24Account(apiKey: string): Observable<Person> {
    return this.http.get<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/beds24/yaago/synchroAccount?beds24ApiKey=' + apiKey, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceSmoobuAccount(apiKey: string): Observable<Person> {
    return this.http.get<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/smoobu/synchronize-account?smoobuApiKey=' + apiKey, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceRefreshSuperhoteAccount(superhoteEmail: string): Observable<void> {
    return this.http.get<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/superhote-support/synchronize-ota-account?superhoteEmail=' + superhoteEmail, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceBookingDotComAccount(corporateOwnerEmail: string): Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/bookingdotcom/synchro?hostEmail=' + corporateOwnerEmail, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceRefreshGiteDeFrance(): Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/test/gite-de-france/synchronizeAllAccount', this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public addPropertyGiteDeFrance(accountId: string, propertyId: string): Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/test/gite-de-france/add-property/' + accountId + '/' + propertyId, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public removePropertyGiteDeFrance(accountId: string, propertyId: string): Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/test/gite-de-france/remove-property/' + accountId + '/' + propertyId, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updateTrialPeriod(trailPeriodUpdate: TrialPeriodUpdate): Observable<Person> {
    return this.http.post<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/trial-period', trailPeriodUpdate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updateAirbnbSwikly(allowAirbnbSwikly: boolean, corporateId: string): Observable<Corporate> {
    return this.http.post<Corporate>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/swikly-airbnb?swiklyAirbnb=' + allowAirbnbSwikly + "&corporateId=" + corporateId, null, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceEmailVerification(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-verification', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forcePremium(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-premium', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forcePerformance(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-performance', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceXp(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-xp', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }
  public forceOps(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-ops', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceUltimate(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-email-ultimate', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceUnPaid(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-unpaid', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public removeUnpaid(emailPerson: string): Observable<Person> {
    return this.http.put<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/remove-unpaid', emailPerson, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }


  public updateMinimumProperties(minimumPropertiesUpdate: MinimumPropertiesUpdate): Observable<Person> {
    return this.http.post<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/minimum-properties', minimumPropertiesUpdate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updatePromotionCode(promotionCodeUpdate: PromotionCodeUpdate): Observable<Person> {
    return this.http.post<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/promotion-code', promotionCodeUpdate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public forceBookingSyncBufferByReference(bookingSyncReference: string): Observable<string> {
    return this.http.get<string>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/test/bookingsync-service/force-by-reference?reference=' + bookingSyncReference, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updateCorporateIndividualCompany(individualCompanyUpdate: IndividualCompanyUpdate): Observable<Corporate> {
    return this.http.post<Corporate>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/corporate-details/individual-company', individualCompanyUpdate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public getStripeTaxRate(): Observable<StripeTaxRate[]> {
    return this.http.get<StripeTaxRate[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/dashboard/person/stripe-tax-rates', this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public updateCorporateRate(corporateAndTaxRate: CorporateAndTaxRate): Observable<Corporate> {
    return this.http.post<Corporate>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/set-tax-rate', corporateAndTaxRate, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public getYaagoAirbnbCorporateOwnerError(): Observable<YaagoAirbnbCorporateOwnerError[]> {
    return this.http.get<YaagoAirbnbCorporateOwnerError[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/airbnb-error', this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public getAirbnbAccountBystatus(status : string): Observable<AirbnbAccount[]> {
    return this.http.get<AirbnbAccount[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/airbnb-accounts/' + status, this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public getAirbnbAccountNotFromServer(): Observable<AirbnbAccount[]> {
    return this.http.get<AirbnbAccount[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/airbnb-accounts/not-from-server', this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

  public getAirbnbAccountFromServer(): Observable<AirbnbAccount[]> {
    return this.http.get<AirbnbAccount[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/airbnb-accounts/from-server', this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors));
  }

}


