import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthInterceptor} from "../interceptors/auth.interceptors";
import {ConfService} from "./conf.service";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {FunFactResults} from "../../model/FunFactResults";
import {Injectable} from "@angular/core";
import {YaagoPerformance} from "../../model/YaagoPerformance";
import {MrrResponse} from "../../model/MrrResponse";
import {AirbnbAccount} from "../../model/AirbnbAccount";
import {BookingDotComAccount} from "../../model/BookingDotComAccount";

@Injectable({
  providedIn: 'root'
})
export class FunFactService {
  constructor(private http: HttpClient,
              private authInterceptor: AuthInterceptor,
              private confService: ConfService) {
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  public getTokenHeaders(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders({Authorization: this.authInterceptor.getAdminKey()}, )};
  }

  public getFunFact(): Observable<FunFactResults> {
    return this.http.get<FunFactResults>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/funfact', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getFunFactMonthly(): Observable<YaagoPerformance[]> {
    return this.http.get<YaagoPerformance[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/funfact/monthly', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getMrrCustomer(): Observable<MrrResponse> {
    return this.http.get<MrrResponse>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/funfact/mrr', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getAirbnbOtaAccount() : Observable<AirbnbAccount[]> {
    return this.http.get<AirbnbAccount[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/ota-account/airbnb', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }
  public getBookingDotComAccount() : Observable<BookingDotComAccount[]> {
    return this.http.get<BookingDotComAccount[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/ota-account/booking-dot-com', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }
}
