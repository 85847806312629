/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./leaflet-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./leaflet-map.component";
import * as i4 from "@angular/router";
var styles_LeafletMapComponent = [i0.styles];
var RenderType_LeafletMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeafletMapComponent, data: {} });
export { RenderType_LeafletMapComponent as RenderType_LeafletMapComponent };
function View_LeafletMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "mini-card-container "], ["style", "display: flex; flex-flow: column; justify-content: center; align-items: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["style", "font-size: 14px; "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentProperty == null) ? null : _co.currentProperty.corporate); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.currentProperty == null) ? null : _co.currentProperty.propertyTitle); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.currentProperty == null) ? null : _co.currentProperty.propertyAddress); _ck(_v, 7, 0, currVal_2); }); }
export function View_LeafletMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "map"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "footer-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Powered by Yaago.com "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeafletMapComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showCorporate; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.mapId, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_LeafletMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-leaflet-map", [], null, null, null, View_LeafletMapComponent_0, RenderType_LeafletMapComponent)), i1.ɵdid(1, 4833280, null, 0, i3.LeafletMapComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeafletMapComponentNgFactory = i1.ɵccf("app-leaflet-map", i3.LeafletMapComponent, View_LeafletMapComponent_Host_0, { me: "me", centerOfTheMap: "centerOfTheMap", propertyPoi: "propertyPoi", whereIamPoi: "whereIamPoi", mapId: "mapId", properties: "properties", property: "property", corporate: "corporate", showAllProperties: "showAllProperties", showProperty: "showProperty", showOwner: "showOwner", showZoomControl: "showZoomControl", maxDistance: "maxDistance", language: "language", showChecks: "showChecks", recapVersion: "recapVersion", defaultZoom: "defaultZoom" }, {}, []);
export { LeafletMapComponentNgFactory as LeafletMapComponentNgFactory };
