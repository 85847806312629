import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../core/services/admin.service";
import {UpSellOnboardingStatistics} from "../../model/V4_Statistics/UpSellOnboardingStatistics";

@Component({
  selector: 'app-m12-yaago-upsells',
  templateUrl: './m12-yaago-upsells.component.html',
  styleUrls: ['./m12-yaago-upsells.component.scss']
})
export class M12YaagoUpsellsComponent implements OnInit {

  upSellOnboardingStatistics: UpSellOnboardingStatistics[] = [];

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.getUpSellStatistics().subscribe(data => {
      this.upSellOnboardingStatistics = data;
    });
  }
  getTotalEuros(currency: string) : string {
    let total = 0;
    this.upSellOnboardingStatistics.forEach( stat => {
      if (stat.currency === currency)
      total += stat.totalCorporateRevenue;
    });
    return total.toFixed(2);
  }

  getTotalEArlyEuros(currency: string) : string {
    let total = 0;
    this.upSellOnboardingStatistics.forEach( stat => {
      if (stat.currency === currency)
        total += stat.totalCorporateEarlyCheckRevenue;
    });
    return total.toFixed(2);
  }

  getTotalLateEuros(currency: string) : string {
    let total = 0;
    this.upSellOnboardingStatistics.forEach( stat => {
      if (stat.currency === currency)
        total += stat.totalCorporateLateCheckRevenue;
    });
    return total.toFixed(2);
  }

  getTotalOthersEuros(currency: string) : string {
    let total = 0;
    this.upSellOnboardingStatistics.forEach( stat => {
      if (stat.currency === currency)
        total += stat.totalCorporateOtherRevenue;
    });
    return total.toFixed(2);
  }


}
