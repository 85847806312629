<div class="y3-margin-top-24 y3-padding-left-16 y3-padding-right-16">
  <div class="y3-margin-bottom-16">
    <table>
      <tr>
        <th style="text-align: left">INDEX</th>
        <th style="text-align: left">CORPORATE NAME</th>
        <th style="text-align: left">OWNER EMAIL</th>
        <th style="text-align: right">Number of UpSells</th>
        <th style="text-align: right">Number of Early</th>
        <th style="text-align: right">EarlyCheckIn  Revenues</th>
        <th style="text-align: right">Number of Late</th>
        <th style="text-align: right">LateCheckOut Revenues</th>
        <th style="text-align: right">Number of Others</th>
        <th style="text-align: right">Other Revenues</th>
        <th style="text-align: right">Total Revenues</th>
      </tr>

      <tr style="background: azure">
        <td style="text-align: left; font-weight: bold">TOTAL EUR</td>
        <td style="text-align: left"></td>
        <td style="text-align: left"></td>
        <td style="text-align: right"></td>
        <td style="text-align: right"></td>
        <td style="text-align: right; font-weight: bold">{{getTotalEArlyEuros('eur') | currency: 'EUR'}}</td>
        <td style="text-align: right"></td>
        <td style="text-align: right; font-weight: bold">{{getTotalLateEuros('eur') | currency: 'EUR'}}</td>
        <td style="text-align: right"></td>
        <td style="text-align: right; font-weight: bold">{{getTotalOthersEuros('eur') | currency: 'EUR'}}</td>
        <td style="text-align: right; font-weight: bold">{{getTotalEuros('eur') | currency: 'EUR'}}</td>
      </tr>
      <tr style="background: azure">
        <td style="text-align: left; font-weight: bold">TOTAL CHF</td>
        <td style="text-align: left"></td>
        <td style="text-align: left"></td>
        <td style="text-align: right"></td>
        <td style="text-align: right"></td>
        <td style="text-align: right; font-weight: bold">{{getTotalEArlyEuros('chf') | currency: 'EUR'}}</td>
        <td style="text-align: right"></td>
        <td style="text-align: right; font-weight: bold">{{getTotalLateEuros('chf') | currency: 'EUR'}}</td>
        <td style="text-align: right"></td>
        <td style="text-align: right; font-weight: bold">{{getTotalOthersEuros('chf') | currency: 'EUR'}}</td>
        <td style="text-align: right; font-weight: bold">{{getTotalEuros('chf') | currency: 'CHF'}}</td>
      </tr>

      <tr class="class-tr" *ngFor="let upsellStats of upSellOnboardingStatistics; let i = index" >
        <td style="text-align: left">{{i+1}}</td>
        <td style="text-align: left">{{upsellStats.corporateName}}</td>
        <td style="text-align: left">{{upsellStats.corporateOwnerEmail}}</td>
        <td style="text-align: right">{{upsellStats.totalCorporateNbSells}}</td>
        <td style="text-align: right">{{upsellStats.totalCorporateNbEarlyCheckSells }} <span class="y3-X-small" *ngIf="upsellStats.totalCorporateNbEarlyCheckSells>0">: Avg {{(upsellStats.totalCorporateEarlyCheckRevenue / upsellStats.totalCorporateNbEarlyCheckSells).toFixed(2) | currency: upsellStats.currency.toUpperCase()}}</span> </td>
        <td style="text-align: right">{{upsellStats.totalCorporateEarlyCheckRevenue | currency: upsellStats.currency.toUpperCase()}}</td>
        <td style="text-align: right">{{upsellStats.totalCorporateNbLateCheckSells}} <span class="y3-X-small" *ngIf="upsellStats.totalCorporateNbLateCheckSells>0">: Avg {{(upsellStats.totalCorporateLateCheckRevenue / upsellStats.totalCorporateNbLateCheckSells).toFixed(2) | currency: upsellStats.currency.toUpperCase()}}</span></td>
        <td style="text-align: right">{{upsellStats.totalCorporateLateCheckRevenue | currency: upsellStats.currency.toUpperCase()}}</td>
        <td style="text-align: right">{{upsellStats.totalCorporateNbOtherSells}}</td>
        <td style="text-align: right">{{upsellStats.totalCorporateOtherRevenue | currency: upsellStats.currency.toUpperCase()}}</td>
        <td style="text-align: right">{{upsellStats.totalCorporateRevenue | currency: upsellStats.currency.toUpperCase()}}</td>
      </tr>

    </table>
  </div>
</div>
