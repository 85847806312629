<div id="{{mapId}}" class="map" >
  <div class="footer-container">
    Powered by Yaago.com
  </div>
</div>

<div class="mini-card-container "  style="display: flex; flex-flow: column; justify-content: center; align-items: center" *ngIf="showCorporate">
  <div style="font-size: 14px; "><strong>{{currentProperty?.corporate}}</strong></div>
  <div>{{currentProperty?.propertyTitle}}</div>
  <div>{{currentProperty?.propertyAddress}}</div>
</div>




